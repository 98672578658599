import tw, { styled } from 'twin.macro';

import ReactTooltip from 'react-tooltip';
import '../App.css';
export const ReactTooltipStyled = styled(ReactTooltip)`
  &.type-dark {
    ${tw` bg-nothotCyan-500 `}

    &:after { 
      ${tw` border-0!`}
    }
    &:before { 
      ${tw` bg-none`}
    }
  }
`;

export const HeadLine =styled.div`
${tw`pl-4 mt-8 mb-6  text-2xl font-bold`} &{}`;

export const UnderLine =styled.div`
${tw`pl-4 mt-8 mb-6  text-lg font-normal`} &{}`;


export const FlexContainer =styled.div`
${tw`flex flex-wrap w-full p-2 `} &{}`;

export const SliderValuesContainer =styled.div`
${tw`flex flex-wrap w-full p-2 md:w-full  lg:w-full 2xl:w-1/2 text-base from-nothotBlack-500`};
& {

      h2{
        ${tw`w-full mt-1 md:mb-3 p-2 cursor-help flex-shrink-0 md:flex-shrink md:w-auto `}
      }

      .Slider{ 
        ${tw`flex-grow mt-3 mb-3 p-2 w-auto`} 
      }

      span{ 
        ${tw`mt-0 mb-3 p-2 text-left w-32 flex`} 
      }
                  
  
    
      input{
        ${tw`pl-0 pr-0 appearance-none bg-transparent flex-shrink border-0 w-full text-right leading-tight focus:outline-none`}
      }
  }`;

export const Unit =styled.div`
${tw`pl-1 pt-0.5 w-auto`} &{}`;
  
export const SliderContainer =styled.div`
${tw`  w-9/12 p-2 ml-4 `} &{}`;

// border-nothotPink-500