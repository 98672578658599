import React, {useState, useEffect} from 'react';

import sanityClient from "../client.js";
import { LanguageSelect,Header,Container,HeaderWrapper, HeaderLogo, Navbar, BmNavbar,BmIcons,Toggle  } from "../styles/menu_style"
import BusinessLogo from '../grafiken/logobusiness.svg';
import {  Link} from "react-router-dom"; //useLocation
import { slide as Menu } from 'react-burger-menu';
import SocialIcons from '../components/SocialIcons';
import {useLanguage} from '../hooks/language-context';
import { useWindowDimensions } from '../hooks/useWindowDimensions';



function HeadMenu(props) {

  const [allTexts, setAllTexts] = useState(null);
  const {dispatchLanguage} = useLanguage();
  const {state: {language}} = useLanguage();
  const { width } = useWindowDimensions();

  function handleLanguageChange(event){
    dispatchLanguage({ type:"changeLanguage", language: event.target.value });
  }

  function getText(key, type){
    if(allTexts){

      if(type==="animationText"){
        if(language in allTexts[key]){
          return allTexts[key][language];
        } 
      }

      if(type==="headline" ){
      if(language in allTexts[key][type]){
         return allTexts[key][type][language]; 
        }
      }

      if(type==="paragraph"){
       if(language in allTexts[key][type]) {
          return(
              <span>
                {allTexts[key][type][language].map((textLine, i)=>{
                return <span key={i+"textLine"}>{textLine["children"][0].text}<br/></span>;
              })}
              </span>
          )
        }
      }
    }

    return null;       
  }

   //SANITY
   useEffect(() => {
    sanityClient.fetch(
      /* groq */`*[_type == "pages"] | order(identifier){
      _id,
      identifier,
      pageElement
  }`).then((data) => {
    
    for (let i=0;i<data.length;i++){
      if(data[i]["identifier"]==="Menu"){
        let pageElements=[];
        data[i]["pageElement"].forEach(element => pageElements.push(element));
        setAllTexts(pageElements);
      }
    }
    }).catch(console.error);
}, []);

  var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      right: '36px',
      top: '25px',
      
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      //background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: 'rgb(255, 255,255)',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
      
    }
    ,
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: 'rgb(0, 0,0)',
      padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }

  return (
<React.Fragment>
      {width < 768 &&
        <Menu
        disableAutoFocus 
          styles={styles}
          customBurgerIcon={<svg
            className=""
            fill="none" strokeLinecap="round"
            strokeLinejoin="round" strokeWidth="2"
            viewBox="0 0 24 24" stroke="currentColor">
            <path d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
          }

          customCrossIcon={<svg className="" fill="none" strokeLinecap="round"
            strokeLinejoin="round" strokeWidth="2"
            viewBox="0 0 24 24" stroke="currentColor">
            <line x2="24" y2="24" />
            <line x1="24" y2="24" />
          </svg>}
        >
          <HeaderLogo className="">
            <div>
              <Link to="/">
                <img src={BusinessLogo} alt="Logo" />
              </Link>
            </div>
          </HeaderLogo>

          <BmNavbar >
            <ul className="">
     
              <li><Link to="/waldwissen">{getText(0,"animationText")}</Link></li>
              <li><Link to="/findyourtree">{getText(1,"animationText")}</Link></li>
              <li><Link to="/beratung">{getText(2,"animationText")}</Link></li>
                <Link to="/CO2Rechner"><button>{getText(3,"animationText")}</button></Link>
                <li><a href="https://weihnachtsbirke.nothot.global/"><span><button className=''>Weihnachtsbirkenaktion</button></span></a></li>
              <li>&nbsp;</li>
              {/** 
              <LanguageSelect>
              Sprache:
              <select onChange={handleLanguageChange}>
                <option value="de">DE</option>
                <option value="en">EN</option>
                <option value="lv">LV</option>
              </select>
            </LanguageSelect>
            */}
            </ul>

        </BmNavbar>

          <BmIcons>
            
            <p>
              
              Not Hot
              <br />
              An der Breiten Wiese 9<br />
              30625 Hannover<br />
              <br />
              (+49) 511 2192 5996<br />
              support@nothot.global
            </p>
            <SocialIcons className="socialIcons" />

          </BmIcons>


        </Menu>
      }
    <Header>
       
        <Container>

                <HeaderWrapper className="">


                    <HeaderLogo className="">
                       <div>
                         <Link to="/">
                           <img src={BusinessLogo} alt="Logo" />
                           </Link>
                        </div>
                    </HeaderLogo>

                   
                    <Toggle >
                    </Toggle>


                    <Navbar className="hidden md:block">
                        <ul className="">
                  
                            <li><Link to="/waldwissen">{getText(0,"animationText")}</Link></li>
                            <li><Link to="/findyourtree">{getText(1,"animationText")}</Link></li>
                            <li><Link to="/beratung">{getText(2,"animationText")}</Link></li>
                            <Link to="/CO2Rechner">
                            <button>
                            {getText(3,"animationText")}
                            </button>
                            </Link>
                            <li><a href="https://weihnachtsbirke.nothot.global/"><span><button className='h-10 px-5 bg-gradient-to-br from-nothotPink-500  via-nothotRed-500 to-nothotRed-500  text-white rounded-lg'>Weihnachtsbirkenaktion</button></span></a></li>
                            {/** 
                            <LanguageSelect>
                              <label>
                              <select onChange={handleLanguageChange}>
                                <option value="de" >DE</option>
                                <option value="en" >EN</option>
                                <option value="lv" >LV</option>
                              </select>
                              </label>
                            </LanguageSelect>
                            */}
                        </ul>
                    </Navbar>

                </HeaderWrapper>
        </Container>

    </Header>
    </React.Fragment>
   
    );
}

export default HeadMenu;