import React, {useState,useEffect} from "react";
import {Routes, Route, useLocation } from "react-router-dom"; //useLocation
import axios from 'axios';
import { isIOS } from 'react-device-detect';

import CookieConsent from "./components/CookieConsent"
import Home from "./components/Home";
import FindTree from "./components/FindTree";
import Content from "./components/Content";
import Beratung from "./components/Beratung";
import PostsMain from "./components/PostsMain";
import CO2Rechner from "./components/Co2Rechner";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import AGB from "./components/AGB";
import FormSend from "./components/FormSend";
import Klingelton from "./components/Klingelton";

import PostDetails from "./components/PostDetails";

import { GlobalStyles } from 'twin.macro'; // new

import GlobalFonts from './fonts/fonts';

import {LanguageProvider} from './hooks/language-context';
import {ContentProvider} from './hooks/content-context';

import sanityClient from "./client.js";

import waldwachsenVideo from "./imgseq/waldwachsen.mp4";
import waldwachsenVideoLow from "./imgseq/waldwachsen_low.mp4";
import waldwachsenVideoIOS from "./imgseq/waldwachsenios.mp4"
import waldwachsenVideoSmall from "./imgseq/waldwachsensm.mp4"

import waldco2Video from "./imgseq/waldboden.mp4"
import waldco2VideoLow from "./imgseq/waldboden_low.mp4";
import waldco2VideoIOS from "./imgseq/waldbodenios.mp4";
import waldco2VideoSmall from "./imgseq/waldbodensm.mp4";

function App() {

  const location = useLocation();
  const [allPosts, setAllPosts] = useState(null);
  const [videoA, setVideoA]=useState(null);
  const [videoAlow, setVideoAlow]=useState(null);
  const [videoB, setVideoB]=useState(null);
  const [videoBlow, setVideoBlow] =useState(null);
  const [viewType, setViewType]=useState(null);

  function fetchVideoBlob(videoLink, videoState) {

    const config = { responseType: 'blob' };
    axios.get(videoLink[0], config).then(response => {
      // createObjectURL with video is bugy on ios!!!
      var vid = URL.createObjectURL(new Blob([response.data], { type: 'video/mp4' }));
      videoState[0](vid);

      //load other videos recursive
      if(videoLink.length>1){
        videoState.shift();
        videoLink.shift();
        fetchVideoBlob(videoLink,videoState);
      }
    }).catch(err => {
      if (err.response) {
        // client received an error response (5xx, 4xx)
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        // client never received a response, or request never left
        console.log(err.request);
      } else {
        console.log('Error', err.message);
      }
      console.log(err.config);
    })
  }

  //SetupVideos early
  useEffect(() => {
    if(isIOS)
    {
        if(window.screen.width<768){
          if(viewType!=="iphone")setViewType("iphone");
        } else
        {
          if(viewType!=="ipad")setViewType("ipad");
        }
    }else{
      if(window.screen.width<768){
        if(viewType!=="mobile")setViewType("mobile");
      }
      else{
        if(viewType!=="desktop")setViewType("desktop");
      }
    }

  },[viewType]);

  //viewTypeChanged
  useEffect(() => {

      switch(viewType){
        case "desktop": fetchVideoBlob([waldwachsenVideoLow, waldco2VideoLow, waldwachsenVideo, waldco2Video],[setVideoAlow,setVideoBlow,setVideoA,setVideoB]);break;
        case "ipad": setVideoA(waldwachsenVideoIOS); setVideoB(waldco2VideoIOS);break; //dont use video blob on ios.
        case "iphone": setVideoA(waldwachsenVideoSmall); setVideoB(waldco2VideoSmall);break; //dont use video blob on ios.
        case "mobile": fetchVideoBlob([waldwachsenVideoSmall, waldco2VideoSmall],[setVideoA,setVideoB]);break;
        default: break;
    }
  },[viewType]);


  //fetch posts for routing...
  useEffect(() => {
    sanityClient.fetch(
      /* groq */`*[_type == "post"] | order(identifier){
      _id,
      identifier,
      slug,
      title,
      mainImage,
      publishedAt,
      body,
        authorName,
        authorPosition,
        authorImage
  }`).then((data) => {
    setAllPosts(data)
    }).catch(console.error);
}, []);
 

  return (
  
    <div>
      <GlobalFonts />
      <GlobalStyles/>
      <LanguageProvider>

      <ContentProvider>
        <Content/>
      <CookieConsent/>
      <Routes location={location}>
     
 

      {allPosts && allPosts.map(post => (   
                        <Route key={post._id} path={`${process.env.PUBLIC_URL +"/"+ post.slug.current}`}
                        element={
                            <PostDetails  postData={post} id={post.slug.current} />
                            }/>
                     ))}

<Route path="/findyourtree/:id/:id"
              element={<FindTree/>}/>

              <Route path="/findyourtree/:id"
              element={<FindTree/>}/>

              <Route path="/findyourtree"
              element={<FindTree/>}/>

              <Route path="/waldwissen"
              element={<PostsMain/>}/>


              <Route path="/beratung"
              element={<Beratung  />}/>
                

              <Route path="/CO2Rechner"
              element={<CO2Rechner />}/>


              <Route path="/Impressum"
              element={<Impressum />}/>
                

              <Route path="/Datenschutz"
              element={<Datenschutz />}/>
                

              <Route path="/AGB"
              element={<AGB />}/>
                
              
              <Route path="/FormSucess"
              element={<FormSend />}/>
              
              <Route path="/Klingelton"
              element={<Klingelton />}/>
              
              <Route path="/Weihnachtsbirke"
              element={ <Home    viewType={viewType} videoAlow={videoAlow} videoA={videoA} videoBlow={videoBlow} videoB={videoB}
              />}/>
              
              <Route path="/"
              element={ <Home    viewType={viewType} videoAlow={videoAlow} videoA={videoA} videoBlow={videoBlow} videoB={videoB}
              />}/>
               

      </Routes>
      </ContentProvider>
      </LanguageProvider>

    </div>

  )
}

export default App
