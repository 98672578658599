import tw, { styled } from 'twin.macro';

export const Main =styled.div`
${tw`bg-white pb-28 py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 relative `};
& {
  }
`

export const TextContainer = styled.main`
${tw`mx-auto  w-full translate-y-0 translate-x-0 transform  bg-white overflow-x-hidden h-auto`};
& {

    h1{
      ${tw`mt-12 text-2xl font-bold leading-7 text-nothotBlack-500   sm:text-3xl sm:truncate`}
    }
    p{
      ${tw`mt-4 max-w-7xl text-base text-nothotBlack-500 `}
     
    }
    td{
      ${tw`mt-4 pr-6 max-w-7xl text-base text-nothotBlack-500 `}
    }
  }`


export const FormContainer = styled.main`
${tw` ml-auto mr-auto w-full `};
& {
    form{
      ${tw` flex flex-wrap bg-white rounded pt-6 pb-8 mb-4`};
     
    }
    
  }`

export const SectionWhite = styled.div`
${tw`relative py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 shadow-inner bg-white z-40`};
& {}`

export const ImgContainer = styled.main`
${tw`relative overflow-x-hidden overflow-y-hidden w-full z-40 h-screen/2`};
& {
  img{
    ${tw`relative object-cover w-full overflow-x-hidden top-0 inset-x-0 bottom-0 h-full z-40`} //h-96 
  }
}`





export const FrameContainer = styled.main`
${tw` w-full px-5 `};
& {}`

export const FormSendMessage = styled.main`
${tw` w-full px-5  text-gray-700`};
& {}`

export const Form = styled.form`
${tw`bg-white rounded pt-6 pb-8 mb-4`};
& {

  }`

export const Frame =  styled.main` 
${props => props.active ?
tw`border rounded w-full py-2 px-3 text-gray-700 filter grayscale-0 border-nothotBlue-500 border-dashed flex`
:
tw`border rounded w-full py-2 px-3 text-gray-700 filter grayscale opacity-50 border-nothotBlue-500  border-dashed flex`
}`


export const FormInput = styled.main`
${tw`mb-4 flex-none w-full pl-5 pr-5  md:w-1/2`};
& {
    label{
      ${tw`block text-nothotBlack-500 text-base mb-2 font-bold`};
    }
    input{
      ${tw` appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md border-nothotBlue-500`};
    }
    textarea{
      ${tw` appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md`};
    }
    
  }`

export const EmittentContainer =styled.div`
${tw`flex flex-wrap w-full`};
& {
  }`

export const Emittent = styled.div`
${tw`min-w-min h-16 flex-grow align-middle `};
& {
    div{
      ${tw`p-4 h-full flex align-middle items-center`}
    }
    h3{
      ${tw` text-xl font-semibold text-center`}
     
    }
    p{
      ${tw`text-base text-gray-600 pl-4 whitespace-nowrap align-middle`}
    }
    span{
      ${tw`text-base text-gray-600 pl-4 whitespace-nowrap justify-self-end w-full text-right`}
    }
    img{
      ${tw`flex-none w-8 h-8`}
    }
    a{
      ${tw`underline border-nothotBlue-500`}
    }
  }`


export const FormButton = styled.button`
${tw`h-10 px-5 bg-gradient-to-br from-nothotBlue-500  via-nothotBlue-500 to-nothotCyan-500  text-white rounded-lg m-1`};
& {
  }
`



export const CollapsibleContainer = styled.main`
${tw`mx-auto  w-full mb-9 bg-white `};
& {

  .Collapsible{
    ${tw` mt-1 bg-nothotCyan-500 text-white`}
    &{
      span{
      ${tw`ml-2 mt-1 pr-2 max-w-7xl text-sm text-white `}
    }
      td{
      ${tw`mt-2 pr-2 max-w-7xl text-sm text-nothotBlack-500 `}
    }
    }
  }

  .Collapsible__contentInner{
    ${tw` bg-gray-100 text-nothotBlack-500`}
    &{
      span{
      ${tw`ml-2 mt-1 pr-2 max-w-7xl text-sm text-nothotBlack-500 `}
    }
    }
  }

  .Collapsible__contentOuter{
    ${tw` bg-gray-100 text-nothotBlack-500`}
  }

  .is-open{
         &::before{
          content: url('grafiken/expand_less.svg');
          ${tw`text-2xl  mt-1 mr-6`}
        }
      }
        .is-closed{
         &::before{
          content: url('grafiken/expand_more.svg');
          ${tw`text-2xl  mt-1 mr-6`}
        }
      }

    .Collapsible__trigger{
      ${tw`ml-4 text-2xl font-bold leading-10! mt-2! block w-full cursor-pointer text-white  sm:sm:truncate`}
      & {  
        
        
          
        
      }
    }
  }`