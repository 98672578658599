import React from 'react';
import Slider from 'rc-slider';
import { SliderContainer,SliderValuesContainer,FlexContainer,HeadLine,ReactTooltipStyled,Unit  } from "../styles/emissionSlider_style";

function EmissionSlider(props) {
    
    var objKeyNames=Object.keys(props.value);

    function getEquivalentInfo(categoryValues,keyName,value){
        let equivalent=categoryValues.find(x => x.valueName.de === keyName).co2Equivalent;
        let co2=equivalent*value[keyName];
        //let infoText="CO2: "+value[keyName]+" x "+equivalent+" = "+numberWithDots(co2)+"kg";
        let co2Eq= numberWithDots(co2)+"kg CO2";
        return co2Eq;
    }

    function numberWithDots(x) {
        x=Math.round(x);
        return x.toString();
     //   return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    }

    const handleStyle = {
        height: 24,
        width: 24,
        marginTop: -12,
        backgroundColor: "#fffff",
        border: "solid 1px #d9228e",
        boxShadow: 'none'
    };

    const trackStyle = {
        height: 1,
        backgroundColor: "#d9228e"
    }

    const railStyle= {
        height: 1
    }

      const onValueChange = (type,value) => {
          props.setValue(props.id,{...props.value, [type]:value}); 
        
      };
      
    return (
        <React.Fragment>

           <HeadLine><br/> {props.text} </HeadLine>
           <ReactTooltipStyled className="tooltip"	/>
<FlexContainer>
            {objKeyNames.map((keyName, index)=>(
               

                    <SliderValuesContainer key={keyName+index}>
                  
                            <h2 data-tip={getEquivalentInfo(props.categoryValues,keyName,props.value)}> {keyName} </h2>
                            <span className="Slider">     
                                <Slider min={props.min} max={props.max}
                            value={props.value[keyName]}
                            onChange={(value) => onValueChange(keyName, value)}
                            railStyle={railStyle}
                            handleStyle={handleStyle}
                            trackStyle={trackStyle}
                        />         
                        </span>
                            <span > <input value={props.value[keyName]} onChange={(e) => onValueChange(keyName,e.target.value)} /><Unit>{props.unit}</Unit></span>
                
                    



                    </SliderValuesContainer>
             
            ))
            
            }
            
            </FlexContainer>
            

        </React.Fragment>
        
        );
}

export default EmissionSlider;