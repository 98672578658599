function FormatSanityContent(content,language, type){
    if(content){
      if(type==="animationText"){
        if(language in content){
          return content[language];
        } 
      }

      if(type==="headline" ){
      if(language in content[type]){
         return content[type][language]; 
        }
      }

      if(type==="paragraph"){
       if(language in content[type]) {
          return(
              <span>
                {content[type][language].map((textLine, i)=>{
                return <span key={i+"textLine"}>{textLine["children"][0].text}<br/></span>;
              })}
              </span>
          )
        }
      }
    }

    return null;       
  }
  export default FormatSanityContent;