import React,{ useEffect, useRef } from 'react';
import ScrollVideo from "./ScrollVideo";
import {Grid, TextOverlay,ImgCanvas,SlideCenter} from "../styles/forestCO2Ani_style";
import gsap from 'gsap';

import {useLanguage} from '../hooks/language-context';
import {useContent} from '../hooks/content-context';
import FormatSanityContent from '../scripts/FormatSanityContent.js';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

function ForestCO2Ani(props) {

const forrestFloorAniLength=1600; //Pixel
const pinAniLength=2800; //Pixel


const { width } = useWindowDimensions();
const {state: {language}} = useLanguage();
const {state: {contentWaldCO2}} = useContent();

const imgCanvasAni02 = useRef(null);
const overlay2HeadArrow= useRef(null);
const overlay2Head1 = useRef(null);
const overlay2Sub1=useRef(null);
const overlay2Sub2=useRef(null);

const svgani1=useRef(null);
const svgani2=useRef(null);
const svgani3=useRef(null);
const svgani4=useRef(null);
const svghani1=useRef(null);
const svghani2=useRef(null);
const svghani3=useRef(null);
const svghani4=useRef(null);
const svgpani1=useRef(null);
const svgpani2=useRef(null);
const svgpani3=useRef(null);
const svgpani4=useRef(null);

 /////////////////////////////////////////////////////////
 useEffect(() => {

  if (props.forestAniRef) {
  const valuesTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: props.forestAniRef.current,
      start: () => 'top top',
      end: () => width<768 ?'3450px':'3750px', //3750
      scrub: true,
      markers: false
    }
  });

  valuesTimeline.fromTo(overlay2Head1.current, { y: 0, opacity: 1 },{ y: -200, opacity: 0, duration: 5 });
  valuesTimeline.fromTo(overlay2HeadArrow.current, { y: 0, opacity: 1 },{ y: 200, opacity: 0, duration: 5 }, "<");
  valuesTimeline.fromTo(overlay2Sub1.current, { y: 300, opacity: 0 }, { y: 0, opacity: 1, duration: 5 });
  valuesTimeline.fromTo(overlay2Sub1.current, { y: 0, opacity: 1 }, { y: -300, opacity: 0, duration: 5, immediateRender: false }, "+=2");
  valuesTimeline.fromTo(overlay2Sub2.current, { y: 300, opacity: 0 }, { y: 0, opacity: 1, duration: 5 });
  valuesTimeline.fromTo(overlay2Sub2.current, { y: 0, opacity: 1 }, { y: -300, opacity: 0, duration: 5, immediateRender: false }, "+=2");

  
  valuesTimeline.from(svgani1.current, { width: 0, duration: 2 }, ">7");
  valuesTimeline.from(svghani1.current, { x: -20,opacity: 0, duration: 0.5, },"-=0.25");
  valuesTimeline.from(svgpani1.current, { x: -20,opacity: 0, duration: 0.5, },"-=0.25");

  valuesTimeline.from(svgani2.current, { width: 0, duration: 5 },"-=0.25");
  valuesTimeline.from(svghani2.current, { x: -20,opacity: 0, duration: 0.5, },"-=0.25");
  valuesTimeline.from(svgpani2.current, { x: -20,opacity: 0, duration: 0.5, },"-=0.25");

  valuesTimeline.from(svgani3.current, { width: 0, duration: 5 },"-=0.25");
  valuesTimeline.from(svghani3.current, { x: -20,opacity: 0, duration: 0.5, },"-=0.25");
  valuesTimeline.from(svgpani3.current, { x: -20,opacity: 0, duration: 0.5, },"-=0.25");

  valuesTimeline.from(svgani4.current, { width: 0, duration: 5 },"-=0.25");
  valuesTimeline.from(svghani4.current, { x: -20,opacity: 0, duration: 0.5, },"-=0.25");
  valuesTimeline.from(svgpani4.current, { x: -20,opacity: 0, duration: 0.5, },"-=0.25");

  const birchParalaxTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: props.overlayContactRef.current,
      start: () => 'top bottom',
      end: () => 'top top',
      scrub: true,
      markers: false
    }
  });
  birchParalaxTimeline.fromTo(props.forestAniRef.current, { y: 0 }, { y: 200, immediateRender: false});


  }
  /////////////////////////////////////////////////////////
  },[props.forestAniRef])


    return (
<div className="bottomPin" style={{position:"relative"}}> {/*style={{translateY : "-300px"}} firstImage={FirstImageBoden}*/}
            <ImgCanvas ref={props.forestAniRef}>       
     
            <ScrollVideo ref={props.videoRef} viewType={props.viewType} videoLow={props.videoLow} video={props.video} fps={30} frames={80}  offsetTop="104" offsetX={.8} length={forrestFloorAniLength} pinlength={pinAniLength} container={imgCanvasAni02} pin=".bottomPin"/>
            {/** 
              <ImageSeqCanvas  setAnimationStatus={setAanimation2status} container={imgCanvasAni02} offsetTop={0} offsetX={.4} length={forrestFloorAniLength} firstImage={FirstImageBoden}  pin=".bottomPin" imgSeqPath={require.context("../imgseq/aniBoden_001webp/", false, /\.(png|webp|jpe?g|svg)$/)}/>
             */}
              <TextOverlay >
                  <Grid>
                      <div id="thinline"></div>
                      <div id="line">
                          <div id="halfleftfiller"></div>
                          <div id="callout" ref={svgani1}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 100"><g><path  vectorEffect="non-scaling-stroke" d="m+10,20l30,30l3000,0" opacity="NaN" stroke="#fff" fill="none"/><ellipse ry="3" rx="3" cy="20" cx="10" fill="#fff"/> <ellipse vectorEffect="non-scaling-stroke"  ry="5" rx="5" cy="20" cx="10" stroke="#fff" fill="none"/></g></svg></div>
                          <div id="label">
                            <h2 ref={svghani1} className="text-4xl font-black self-center ">{contentWaldCO2 && FormatSanityContent(contentWaldCO2[3],language,"headline")}</h2>
                            <p ref={svgpani1}>{contentWaldCO2 && FormatSanityContent(contentWaldCO2[3],language,"paragraph")}</p>
                          </div>

                      </div>
                      <div id="line">
                          <div id="halffiller"></div>
                          <div id="callout" ref={svgani2}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 100"><g><path  vectorEffect="non-scaling-stroke" d="m+10,80l30,-30l3000,0" opacity="NaN" stroke="#fff" fill="none"/><ellipse ry="3" rx="3" cy="80" cx="10" fill="#fff"/> <ellipse vectorEffect="non-scaling-stroke"  ry="5" rx="5" cy="80" cx="10" stroke="#fff" fill="none"/></g></svg></div>
                          <div id="label">
                            <h2 ref={svghani2} className="text-4xl font-black self-center ">{contentWaldCO2 && FormatSanityContent(contentWaldCO2[4],language,"headline")}</h2>
                            <p ref={svgpani2}>{contentWaldCO2 && FormatSanityContent(contentWaldCO2[4],language,"paragraph")}</p>
                          </div>
                      </div>
                      <div id="line"></div>
                      <div id="line">
                          <div id="bigfiller"></div>
                          <div id="callout" ref={svgani3}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 100"><g><path  vectorEffect="non-scaling-stroke" d="m+10,80l30,-30l3000,0" opacity="NaN" stroke="#fff" fill="none"/><ellipse ry="3" rx="3" cy="80" cx="10" fill="#fff"/> <ellipse vectorEffect="non-scaling-stroke"  ry="5" rx="5" cy="80" cx="10" stroke="#fff" fill="none"/></g></svg></div>
                          <div id="label">
                            <h2 ref={svghani3} className="text-4xl font-black self-center "><mark>{contentWaldCO2 && FormatSanityContent(contentWaldCO2[5],language,"headline")}</mark></h2>
                            <p ref={svgpani3}>{contentWaldCO2 && FormatSanityContent(contentWaldCO2[5],language,"paragraph")}</p>
                          </div>
                      </div>
                      <div id="line">
                          <div id="halffiller"></div>
                          <div id="callout" ref={svgani4}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 100"><g><path  vectorEffect="non-scaling-stroke" d="m+10,80l30,-30l3000,0" opacity="NaN" stroke="#fff" fill="none"/><ellipse ry="3" rx="3" cy="80" cx="10" fill="#fff"/> <ellipse vectorEffect="non-scaling-stroke"  ry="5" rx="5" cy="80" cx="10" stroke="#fff" fill="none"/></g></svg></div>
                          <div id="label">
                            <h2 ref={svghani4} className="text-4xl font-black self-center "><mark>{contentWaldCO2 && FormatSanityContent(contentWaldCO2[6],language,"headline")}</mark></h2>
                            <p ref={svgpani4}>{contentWaldCO2 && FormatSanityContent(contentWaldCO2[6],language,"paragraph")}</p>
                          </div>
                      </div>

                  </Grid>
                
              <SlideCenter>
                <h1 ref={overlay2Head1}> <mark>{contentWaldCO2 && FormatSanityContent(contentWaldCO2[0],language,"animationText")}</mark></h1> <br/> 
                <svg ref ={overlay2HeadArrow} xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" fill="none" viewBox="0 0 24 24" stroke="#333333"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" /></svg>                 
                </SlideCenter>
                <SlideCenter>
                <h1 ref={overlay2Sub1}>  <mark>{contentWaldCO2 && FormatSanityContent(contentWaldCO2[1],language,"animationText")} </mark></h1>   <br/> 
                </SlideCenter>
                <SlideCenter>
                <h1 ref={overlay2Sub2}> <mark> {contentWaldCO2 && FormatSanityContent(contentWaldCO2[2],language,"animationText")} </mark> </h1><br/>
                </SlideCenter> 
            </TextOverlay>

            </ImgCanvas>


          </div>
    );
}

export default ForestCO2Ani;