import tw, { styled } from 'twin.macro';


export const Header=styled.div`
${tw` py-8  mt-0 sticky w-full top-0 bg-white z-50`};
&{}`
export const Container=styled.div`
${tw`px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto`};
&{}`

export const HeaderWrapper=styled.div`
${tw`flex items-center justify-between`};
&{}`

export const HeaderLogo = styled.div`
    ${tw``};
    & {
      div {
        ${tw` justify-center  md:justify-start `}
      }
      img{
        ${tw`h-4 `}
      }
    } `

export const Navbar= styled.nav`
${tw`hidden md:block`};
& {
      ul {
        ${tw` flex space-x-8  text-nothotBlue-500 items-center text-base font-medium w-max`}
      }
      li {
        ${tw` hover:text-nothotBlue-500 `}
      }
       button {
        ${tw` h-10 px-5 bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500  text-white rounded-lg w-max`};
        }
      a{
        & { 
          span {
            & {
              button {
              ${tw` h-10 px-5 bg-gradient-to-br from-nothotPink-500  via-nothotPink-500 to-nothotRed-500  text-white rounded-lg`};
            }
          }
        }
        }
      }
      
      h4 {
        ${tw` h-10 px-5 text-nothotBlue-500 border border-nothotBlue-500 rounded-lg`};
      }
    }`

export const BmNavbar= styled.nav`
${tw`hidden md:block`};
& {
      ul {
        ${tw` flex mt-8 flex-col space-y-2  text-nothotBlue-500 items-start text-base font-medium `}
      }
      li {
        ${tw` hover:text-nothotBlue-500 `}
      }
      
      button {
        ${tw` h-10 px-5 bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500  text-white rounded-lg`};
      
    }
    a{
      & { 
        span {
          & {
            button {
        ${tw` h-10 px-5 bg-gradient-to-br from-nothotPink-500  via-nothotRed-500 to-nothotRed-500  text-white rounded-lg`};
      }
    }
  }}}
      h4 {
        ${tw` h-10 px-5 text-nothotBlue-500 border border-nothotBlue-500 rounded-lg`};
      }
      
    }`

export const BmIcons=styled.div`
${tw`absolute bottom-0 inset-x-0  mb-8 px-5`};
&{
  p {
        ${tw`inset-x-0 px-5 flex mb-16  flex-col space-y-2  text-nothotBlack-500 items-start font-medium text-xs `}
      }
}`

export const Toggle= styled.div`
${tw`md:hidden`};
& {
      button {
        ${tw`h-10 px-5`};
	
      }
      svg {
        ${tw`h-6 w-6 fill-current text-nothotBlack-500`}
      }
    }`
  
  export const LanguageSelect= styled.div`
  ${tw`block`};
  & {
       select {
        ${tw`h-10 border-none outline-none appearance-none text-base font-medium text-nothotBlue-500 cursor-pointer`};
       }
       option {
        ${tw`appearance-none text-base font-medium`};
       } 
    }`
  