import React,{ useEffect, useState,useRef} from 'react';
import { useNavigate } from 'react-router-dom';

import sanityClient from "../client.js";
import {useLanguage} from '../hooks/language-context';
import {FormInput,FormButton, FormContainer} from "../styles/contact_style";

function ContactForm(props) {
  const formRef=useRef(null);

  const history = useNavigate();
 
  //const formSend = () => history.push('FormSucess');

  function formSend() {
    history('/FormSucess')
  }

  var formStructure={
    "name":"",
    "mail":"",
    "message":""

  };
    const [allTexts, setAllTexts] = useState(null);
    const [form, setForm] = useState(formStructure);
    const {state: {language}} = useLanguage();

//SANITY
    useEffect(() => {
        sanityClient.fetch(
          /* groq */`*[_type == "pages"] | order(identifier){
          _id,
          identifier,
          pageElement
      }`).then((data) => {
        
        for (let i=0;i<data.length;i++){
          if(data[i]["identifier"]==="Kontakt"){
            let pageElements=[];
            data[i]["pageElement"].forEach(element => pageElements.push(element));
            setAllTexts(pageElements);
          }
        }
        }).catch(console.error);
    }, []);

    function sendRequest(e){
      e.preventDefault();
      let message=JSON.stringify({...form,...props.co2CategoryValues,...props.co2Values});
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "message": message,
                              "senderEmail": form.email,
                              "senderName": form.firma+"_"+form.nachname
      })
    };
   
    
      //Mail to Sender
      /*
      fetch("https://www.nothot.global/.netlify/functions/sendmailresponse", requestOptions)
      .then(res => {})
      .then(
        data => {
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("SENDMAIL ERROR:"+error)
        }
      )
*/
      //Mail to NotHot
      //  await fetch(`${process.env.URL}/.netlify/functions/emails/subscribed`, {

      fetch("https://www.nothot.global/.netlify/functions/sendmail", requestOptions)
      .then(res => {})
      .then(
        data => {
         formRef.current.reset();
         setForm(formStructure);
         formSend();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("SENDMAIL ERROR:"+error)
        }
      );



      
    }
    
    function getText(key, type){
        if(allTexts){
    
          if(type==="animationText"){
            if(language in allTexts[key]){
              return allTexts[key][language];
            } 
          }
    
          if(type==="headline" ){
          if(language in allTexts[key][type]){
             return allTexts[key][type][language]; 
            }
          }
    
          if(type==="paragraph"){
           if(language in allTexts[key][type]) {
              return(
                  <span>
                    {allTexts[key][type][language].map((textLine, i)=>{
                    return <span key={i+"textLine"}>{textLine["children"][0].text}<br/></span>;
                  })}
                  </span>
              )
            }
          }
        }
    
        return null;       
      }

    function onFormChange(e){
      let changedForm=form;
      changedForm[e.target.id]=e.target.value;
      changedForm["subject"]="NOTHOT WEBFORM:"+form.firma+", "+form.nachname; //Change subject for sender...
      setForm(changedForm);
    }

    return (
            <FormContainer>
            
                <form onSubmit={sendRequest} ref={formRef}>
                <FormInput >
                        <label> {getText(2,"animationText")}</label>
                        <input id="firma" type="text" placeholder={getText(2,"animationText")} onChange={onFormChange} ></input>
                        {/*<span style={{ color: "red" }}>error</span>*/}
                    </FormInput>
                    <FormInput/>

                    <FormInput >
                        <label> {getText(3,"animationText")}</label>
                        <input id="vorname" type="text" placeholder={getText(3,"animationText")} onChange={onFormChange}></input>
                    </FormInput>
                    
                    <FormInput >
                        <label> {getText(4,"animationText")}</label>
                        <input id="nachname" type="text" placeholder={getText(4,"animationText")} onChange={onFormChange} required></input>
                    </FormInput>
                    
                    
                    
                    <FormInput >
                        <label> {getText(5,"animationText")}</label>
                        <input id="email" type="email" placeholder={getText(5,"animationText")} onChange={onFormChange} required></input>
                    </FormInput>
                    <FormInput >
                        <label> {getText(6,"animationText")}</label>
                        <input id="telefon" type="tel" placeholder={getText(6,"animationText")} onChange={onFormChange}></input>
                    </FormInput>


                    <FormInput >
                        <label> {getText(7,"animationText")}</label>
                        <textarea id="nachricht" type="textarea" rows="4" placeholder={getText(7,"animationText")}  onChange={onFormChange}></textarea> 
                        {
                        //value={this.state.value}
                        }
                    </FormInput>
                    <FormInput/>

                    <FormButton>{getText(8,"animationText")}</FormButton>
                </form>
                
            </FormContainer>
       
    );
}

export default ContactForm;