import React from 'react';
import { Main} from "../styles/footer_style";
import {  Link} from "react-router-dom"; 
import SocialIcons from './SocialIcons';


const Footer = () => {
    return (
        <Main>
            
            <h1 >© {new Date().getFullYear()}</h1>



            <Link to="/Impressum">
            <h1>Impressum</h1>
            </Link>
           
            <Link to="/Datenschutz">
            <h1>Datenschutz</h1>
            </Link>

            <Link to="/AGB">
            <h1>AGB</h1>
            </Link>
            <span>
           <SocialIcons></SocialIcons>

            </span>
            
        </Main>
    );
};

export default Footer;