import React, { useState, useEffect } from 'react';
import 'rc-slider/assets/index.css';
import HeadMenu from "./HeadMenu";
import EmissionSlider from "./EmissionSlider";
import EmissionFormular from "./EmissionFormular";

import FormatSanityContent from '../scripts/FormatSanityContent.js';
import {useContent} from '../hooks/content-context';
import {useLanguage} from '../hooks/language-context';

import { TabFooter,StickyFooter, Result, Buttons, BackButton, SectionGradient, SectionWhite, TextContainer, SendButton, STab, STabs, STabList, STabPanel } from "../styles/co2Rechner_style";


import 'react-tabs/style/react-tabs.css';

function Co2Rechner(props) {

    
    const {state: {language}} = useLanguage();
    const {state: {valuesCO2Rechner,contentCO2Rechner,globalValues}} = useContent();
    

    const [co2Values, setCO2Values] = useState(0);
    const [co2Categories, setCo2Categories] = useState(0);
    const [co2, setCO2] = useState(0);
    const [waldflaeche, setWaldflaeche] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);

    function sendRequest(){
        console.log("Nachricht absenden");
    }
    function setTab(dir){
        setTabIndex(Math.min(Math.max(tabIndex+dir, 0), 5));
    }

    function numberWithDots(x) {
        x = Math.round(x);
        return x.toString();
        // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    }

    //Consitency Check
    function getDeepKeys(obj) {
        var keys = [];
        for (var key in obj) {
            keys.push(key);
            if (typeof obj[key] === "object") {
                var subkeys = getDeepKeys(obj[key]);
                //keys = keys.concat(subkeys.map(function (subkey)
                keys = keys.concat(subkeys.map( (subkey)=> {
                    return key + "." + subkey;
                }));
            }
        }
        return keys;
    }

    function compareDeepKeys(a, b) {
        var aKeys = getDeepKeys(a).sort();
        var bKeys = getDeepKeys(b).sort();
        return (JSON.stringify(aKeys) === JSON.stringify(bKeys))
    }

    //ProcessSliderData
    function processSlider(id, object) {
        setCO2Values({ ...co2Values, [id]: object });
    }

    useEffect(() => {
        if (co2Values && globalValues) {

           
            //Calculate Emissions
            let co2 = 0;
            let co2Categories = {};
            //CO2 Wert insgesamt
            for (let i = 0; i < valuesCO2Rechner.length; i++) {

                //CO2 Wert Kategorie
                let co2Item = 0;
                co2Categories[valuesCO2Rechner[i].category.de] = 0;
                for (var value in co2Values[valuesCO2Rechner[i].category.de]) {
                    co2Item = co2Values[valuesCO2Rechner[i].category.de][value] * valuesCO2Rechner[i].categoryValues.find(x => x.valueName.de === value).co2Equivalent;
                    co2 += co2Item;
                    co2Categories[valuesCO2Rechner[i].category.de] += co2Item;
                }
            }
            setCO2(co2);
            setCo2Categories(co2Categories);
            setWaldflaeche((co2 * globalValues[0]["co2toha"]).toFixed(2)); 
            localStorage.setItem('co2Values', JSON.stringify(co2Values));
            localStorage.setItem('co2', co2);
            localStorage.setItem('co2Categories', JSON.stringify(co2Categories));
        

        }
    }, [co2Values,globalValues])

    useEffect(() => {
        if (valuesCO2Rechner) {            
            // structure and zero remote co2 data
            var remoteCo2Values = {};
            var co2Categories = {};

            for (let i = 0; i < valuesCO2Rechner.length; i++) {
                var categoryValues = {};
                co2Categories[valuesCO2Rechner[i].category.de] = 0;
                for (let ii = 0; ii < valuesCO2Rechner[i].categoryValues.length; ii++) {
                    categoryValues[valuesCO2Rechner[i].categoryValues[ii].valueName.de] = 0; //.de !!! TODO
                }
                remoteCo2Values[valuesCO2Rechner[i].category["de"]] = categoryValues; //de !! TODO
            }

            var localCo2Values = JSON.parse(localStorage.getItem('co2Values'));

            // Check if remote Structure has changed
            if (compareDeepKeys(localCo2Values, remoteCo2Values)) {
                //Structur same: keep local Storage
                setCO2Values(localCo2Values);
                setCo2Categories(co2Categories); //TODO Werte ausrechnen!
            }
            else {
                //Take the new zeroed Structure and override local Storage
                setCO2Values(remoteCo2Values);
                setCo2Categories(co2Categories);
                localStorage.setItem('co2Values', JSON.stringify(remoteCo2Values));
            }
        }
    }, [valuesCO2Rechner]);

    return (
        <div>

            <HeadMenu />
            <SectionGradient>
                <TextContainer>
                    <h1>{contentCO2Rechner && FormatSanityContent(contentCO2Rechner[0],language,"headline")}</h1>
                    <p> {contentCO2Rechner && FormatSanityContent(contentCO2Rechner[0],language,"paragraph")}</p>
                    
                </TextContainer>
            </SectionGradient>

            <SectionWhite>

                <STabs selectedTabClassName='is-selected'
                    selectedTabPanelClassName='is-selected'
                    selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>

                    <STabList>
                        {co2Values && valuesCO2Rechner.map((value, index) =>
                            <STab key={"tab_" + value.category.de}><span>{value.category.de}</span><div>{numberWithDots(co2Categories[value.category.de]) + " kg"}</div></STab>
                        )}
                        <STab key="absenden"><span>Absenden</span><div></div></STab>

                    </STabList>

                    {co2Values && valuesCO2Rechner.map((value, index) =>
                        <STabPanel key={"panel_" + value.category.de}>
                            <EmissionSlider id={value.category.de} text={value.text.de} categoryValues={value.categoryValues} value={co2Values[value.category.de]} setValue={processSlider} unit={value.valueType} min={0} max={value.sliderMax} />
                        </STabPanel>
                    )}
                    <STabPanel key="panel_absenden">
                        <EmissionFormular co2Values={co2Values} co2CategoryValues={co2Categories}></EmissionFormular>
                    </STabPanel>
                </STabs>
            </SectionWhite>
            <StickyFooter>
                <TabFooter>
                    <Result>
                    {contentCO2Rechner && FormatSanityContent(contentCO2Rechner[1],language,"animationText")}: <span>{numberWithDots(co2)} kg </span> <br />
                    {contentCO2Rechner && FormatSanityContent(contentCO2Rechner[2],language,"animationText")}: <span>{waldflaeche} ha </span>
                    </Result>
                    <Buttons>
                        <BackButton onClick={()=>setTab(-1)} style={{cursor: tabIndex<=0 && "not-allowed"}}>
                        {contentCO2Rechner && FormatSanityContent(contentCO2Rechner[3],language,"animationText")}
                        </BackButton>
                        { tabIndex>=5?
                        <SendButton onClick={()=>sendRequest()}>
                        <span>{contentCO2Rechner && FormatSanityContent(contentCO2Rechner[5],language,"animationText")}</span>
                        </SendButton>
                        :
                        <SendButton onClick={()=>setTab(1)}>
                            <span>{contentCO2Rechner && FormatSanityContent(contentCO2Rechner[4],language,"animationText")}</span>
                        </SendButton>

}
                        
                    </Buttons>
                </TabFooter>
            </StickyFooter>
        </div>
    );
}

export default Co2Rechner;