import React, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

function ScrollVideo(props) {

  const videoElement = useRef(null);

  let videoFrames= props.frames;//bgVideo.duration*25;
  var lastFrame = -1;
  let timeObj = { currentFrame: 0 }
  const scrollTrigger = [];


  //VIDEO BLOBS on IOS not working
useEffect(()=>{
  if(props.viewType!=="ipad" && props.viewType!=="iphone")
  {
    videoElement.current.src=props.video;
  }
  else{
    videoElement.current.src=props.video+"#t=0.001";
  }
},[props.video])

useEffect(()=>{
  if(props.viewType!="ipad" && props.viewType!=="iphone" && !props.video) //Low only if no better quality available
  {
    videoElement.current.src=props.videoLow;
  }
  
},[props.videoLow])


  useEffect(() => {
    scrollTrigger.push(ScrollTrigger.create({
      id: "scroll",
      trigger: videoElement.current,
      scrub: false,
      start: "top-=" + props.offsetTop + "top",
      end: "bottom+=" + props.pinlength + " top",
      markers: false,
      pin: props.pin,
      pinSpacing: true, 
      invalidateOnRefresh: true,
    }));


    scrollTrigger.push(ScrollTrigger.create({
      id: "scroll",
      trigger: videoElement.current,
      scrub: false,
      start: "top-=" + props.offsetTop + "top",
      end: "bottom+=" + props.length + " top",
      markers: false,
      invalidateOnRefresh: true,

      onUpdate: self => {
        gsap.to(timeObj, {
          duration: 0,//+1  obj.duration
          currentFrame: (Math.floor(self.progress * videoFrames)),
          onUpdate: () => {

            let frame = Math.round(timeObj.currentFrame - 1);

            if (frame) {
              let currentFrame = Math.trunc(Math.min(videoFrames, Math.max(0, frame)));
              if (currentFrame !== lastFrame) {
                lastFrame = currentFrame;
            
                //TRIGGER VIDEOPLAY
                if(currentFrame && videoElement.current)
                {
                  videoElement.current.pause();
                  videoElement.current.currentTime = currentFrame/props.fps;
                }

              }
            }
          }
        })
      },

      onLeave: self => {
      }
    }));
 
  
  }, [videoElement]);


  return (
    <React.Fragment>
   

          <video
          ref={videoElement}  muted playsInline type="video/mp4"  preload="auto"></video>

    </React.Fragment>
  );
}

export default ScrollVideo;