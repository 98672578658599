import tw, { styled } from 'twin.macro';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

export const SectionWhite = styled.div`
${tw` pt-0 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 `};
&  {}` 

export const SectionGradient = styled.div`
${tw`py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500 w-full h-full`};
&  {}` 


export const SectionGray = styled.div`
${tw`pt-2 px-6 lg:px-32  md:px-20 xl:px-56 2xl:px-80  shadow-inner transform -translate-y-20  /*fixed bottom-0 w-full bg-gradient-to-b from-gray-100 to-white*/ `};
& {}`


export const StickyFooter = styled.div`
${tw`pt-2 px-6 lg:px-32  md:px-20 xl:px-56 2xl:px-80  shadow-inner fixed -bottom-20 bg-white w-full /* bg-gradient-to-b from-gray-100 to-white*/ z-50  `};
& {}`

export const STabs = styled(Tabs)`
  ${tw` text-lg  text-nothotBlack-500 rounded-lg transform -translate-y-20 pb-16`};
`;
export const STabList = styled(TabList)`
${tw` m-0 flex flex-wrap w-full h-20`};
 // list-style-type: none;
 
`;

export const STab = styled(Tab)`
${tw`transition-all border-0 border-l p-2 text-gray-500 w-8 cursor-pointer overflow-hidden bg-white opacity-75 sm:w-20 md:pl-4  lg:w-1/6` };
 
  &.is-selected {
    ${tw`border-nothotPink-500 border-b-0 border-l-8  flex-grow text-nothotBlack-500 sm:block md:w-1/6 bg-white opacity-100`};
  }

  &:focus {
    ${tw` outline-none `};
  }
  div {
    ${tw` font-bold  text-sm sm:text-base whitespace-nowrap`};
  }
`;

export const STabPanel = styled(TabPanel)`
${tw` hidden min-h-1/2  p-1 -mt-1`};
  &.is-selected {
    ${tw` block`};
  }
`;

export const SendButton=styled.button` 
${tw`  h-10 px-5 bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500  text-white rounded-lg mx-2 text-base`};`

export const BackButton=styled.button`
${tw`  border h-10 px-5 border-nothotBlue-500 text-nothotBlue-500 rounded-lg text-base`};`

export const TextContainer = styled.main`
${tw`mx-auto  w-full translate-y-0 translate-x-0 transform text-white  overflow-x-hidden h-auto`};
& {

    h1{
      ${tw`mt-12 text-2xl font-bold leading-7   sm:text-3xl sm:truncate`}
    }
    p{
      ${tw`mt-4 mb-20 max-w-7xl text-base `}
     
    }
  }`

export const TextContainerAlt = styled.main`
${tw`w-full translate-y-0 translate-x-0 transform overflow-x-hidden h-auto`};
& {

    h1{
      ${tw`text-3xl mb-8 font-bold leading-7 text-nothotBlack-500  sm:text-5xl sm:truncate text-center `}
    }
    p{
      ${tw`mb-16 text-base text-justify lg:text-center lg:px-32 xl:px-40 2xl:px-48 text-gray-600`}
    }
    #quote{
      ${tw`italic`}
    }
  }`

export const TabFooter =styled.div`
${tw` block sm:flex sm:p-8  pb-24 sm:pb-32 text-lg  text-nothotBlack-500`};
& span{
  ${tw`text-base  font-bold `};
  }`

export const Buttons =styled.div`
${tw`w-full sm:w-1/2 flex pt-4 sm:pt-12 sm:pt-0 justify-end`};
&  {}`
  
export const Result =styled.div`
${tw`w-full sm:w-1/2 `};
& {
  }`

export const CollapsibleContainer = styled.main`
${tw`mx-auto  w-full mb-9 bg-white `};
& {

  .Collapsible{
    ${tw`ml-8 mr-8 mt-10 bg-nothotCyan-500 text-white`}
  }


  .Collapsible__contentOuter{
    ${tw` bg-gray-100 text-nothotBlack-500`}
  }

  .is-open{
         &::before{
          content: url('grafiken/expand_less.svg');
          ${tw`text-2xl  mt-2 mr-6`}
        }
      }
        .is-closed{
         &::before{
          content: url('grafiken/expand_more.svg');
          ${tw`text-2xl  mt-2 mr-6`}
        }
      }

    .Collapsible__trigger{
      ${tw`ml-4 text-2xl font-bold leading-10! mt-14! block w-full cursor-pointer text-white  sm:sm:truncate`}
      & {  
        
        
          
        
      }
    }
  }`
