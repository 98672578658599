import React from 'react';

import { SocialIcon } from 'react-social-icons';

function SocialIcons(props) {
    return (
        <div>
             <SocialIcon url="https://www.instagram.com/not_hot_global/" style={{marginLeft:"12px",marginRight:"12px", height: 30, width: 30 }}/>
            <SocialIcon url="https://www.facebook.com/nothot.global" style={{marginLeft:"12px",marginRight:"12px", height: 30, width: 30}}/>
            <SocialIcon url="https://www.youtube.com/channel/UC7HJ-5_-b8hbc0opPPz_o6Q" style={{marginLeft:"12px",marginRight:"12px", height: 30, width: 30}}/>
        </div>
    );
}

export default SocialIcons;