import tw, { styled } from 'twin.macro';

export const Main =styled.div`
${tw`bg-gray-100`};
& {
  }`

export const Headline =styled.h1`
${tw`my-12 text-2xl font-bold leading-7 text-nothotBlack-500   sm:text-3xl sm:truncate`};
& {
  }`

export const Section =styled.div`
${tw`py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex flex-wrap bg-white `};
& {
  }`

export const Container =styled.div`
${tw`flex flex-wrap `};
& {
  h1{
    ${tw`mt-8 text-2xl font-bold leading-7 text-nothotBlack-500 sm:text-3xl sm:truncate`}
  }
  }`

export const Card = styled.div`
${tw`p-4 md:w-1/2 xl:w-1/3 sm:mb-0 mb-6`};
& {
    h2{
      ${tw`text-xl font-medium text-nothotBlack-500 mt-5`}
    }
    p{
      ${tw`text-base leading-relaxed mt-2 text-gray-600 h-20 overflow-hidden line-clamp-3`}
    }  
    a{
      ${tw`text-nothotBlue-500 inline-flex items-center mt-3`}
        svg{
          ${tw`w-4 h-4 ml-2`}
      }
    }
    div{
      ${tw`rounded-lg h-64 overflow-hidden`}& {
        img{
          ${tw`object-cover object-center h-full w-full`}
        }
      }
    }
    
  }`



