import React, {useEffect} from 'react';
import {  Link} from "react-router-dom"; 

import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url'

import { Card,Section,Container,Main,Headline } from "../styles/postsMain_style";
import HeadMenu from "./HeadMenu";
import Footer from "../components/Footer";

import {useContent} from '../hooks/content-context';

const sanityImageBuilder = imageUrlBuilder(sanityClient);

function getSanityImageUrl(source) {
    return sanityImageBuilder.image(source)
  }

const PostsMain = (props) => {

    useEffect(()=>{
        window.scrollTo(0, 0);}
        ,[]);

    const {state: {contentPosts}} = useContent();
    
    return (
        <Main>
            <HeadMenu />

            <Section>
            <Headline>Wald- und Klimawissen</Headline>
            {contentPosts &&
                <Container>
                {               
                    contentPosts.map((value,index) => 
                    
                            <Card key={value._id}>

                                <div>
                                    <img alt="headerImage" src={getSanityImageUrl(value.mainImage.asset).width(400).height(400).focalPoint(value.mainImage.hotspot.x, value.mainImage.hotspot.y).fit("crop").crop("focalpoint").url()}></img>
                                </div>
                                <h2>{value.title}</h2>
                                <p>{value.body.map((text, index) =>
                        text.style === "h1" ?
                            <h2 key={"post_h2"+index}>{text.children[0].text}</h2>
                            :
                            <p key={"post_p"+index}>{text.children[0].text}</p>
                    )}</p>
                                <Link to={"/"+value.slug.current}> weiterlesen<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"><path d="M5 12h14M12 5l7 7-7 7"></path></svg>
                                </Link>

                            </Card>
                            
                    )
                } 
                </Container>
            }
            </Section>
            <Footer/>
        </Main>
    );
};


export default PostsMain;
