import React,{useEffect,useState} from 'react';
import HeadMenu from "./HeadMenu";
import Footer from "../components/Footer";
import { SectionGray, SectionWhite,TextContainer } from "../styles/impressum_style";

import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";

function AGB(props) {

  const [textDE, setTextDE] = useState(null);

  const serializers = {
    types: {
      block: (props) => {
        const { style = "normal" } = props.node;
  
        if (style === "sectionGray") {
          return <SectionGray>{props.children}</SectionGray>;
        }

        if (style === "sectionWhite") {
          return <SectionWhite>{props.children}</SectionWhite>;
        }

        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, "");
          return React.createElement(
            style,
            { className: `heading-${level}` },
            props.children
          );
        }
  
        if (style === "blockquote") {
          return <blockquote>- {props.children}</blockquote>;
        }
  
        // Fall back to default handling
        return BlockContent.defaultSerializers.types.block(props);
      },
      code: (props) =>
        console.log("code block", props) || (
          <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
          </pre>
        )
    },
    marks: {
      code: (props) => console.log("code", props) || <code>{props.children}</code>
    }
  };

  useEffect(() => {
    sanityClient.fetch(
      /* groq */`*[_type == "legalTexts"] | order(id){
      title,
      textDE,
      textEN,
      textLV
  }`).then((data) => {
      setTextDE(data.find(x => x.title === "AGB").textDE);
   
    }).catch(console.error);
  }, []);

    return (
        <div>
        <HeadMenu />
       

             {/** <ImageBox><div id="row1"><img id="img1" src={PlantTree03}/><img id="img2" src={PlantTree02}/> </div><div id="row2"><img id="img3" src={PlantTree02}/><img id="img4" src={PlantTree04}/></div></ImageBox>**/}

             <SectionGray>        
              <TextContainer>
              {textDE && <BlockContent
                        blocks={textDE}
                        serializers={serializers}
              />
         }
           
              </TextContainer>
              </SectionGray>




    <Footer/>
  </div>
    );
}

export default AGB;