import tw, { styled } from 'twin.macro';

export const Main =styled.div`
${tw` min-h-screen bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500`};
& {
  }
`
export const SectionWhite = styled.div`
${tw`relative py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 shadow-inner bg-white z-40`};
& {}`

export const SectionGray = styled.div`
${tw`relative py-28 pb-2 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 shadow-inner bg-gray-100 z-40`};
& {}`

export const SectionTranparent= styled.div`
${tw`relative py-28 pb-2 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 z-40 bg-gradient-to-t from-transparent via-transparent to-nothotBlack-500 h-screen pointer-events-none`};
& {
}`


export const SectionWrapper = styled.div`
${tw`block lg:flex flex-wrap w-full`};
& {}`

export const MapSpace = styled.div`
${tw`mx-auto w-full h-screen relative z-40 m-auto pointer-events-none`};
& {}`

export const TextContainerAlt = styled.main`
${tw`mx-auto w-full text-nothotBlack-500 h-auto`};
& {

    h1{
      ${tw`mt-12 text-2xl font-bold leading-7 sm:text-3xl sm:truncate`}
    }
    p{
      ${tw`mt-4 max-w-7xl text-base `}
    }
    span{
      ${tw`text-nothotPink-500`}
    }
}`

export const MapCopyright = styled.div`
${tw`opacity-0 fixed top-32 w-96 h-36 left-3 text-sm text-gray-50`};
& {
  }
`

export const Map = styled.div`
${tw`mx-auto w-full transform h-screen-120 fixed top-48 z-10 opacity-0`};
& {
  }
`

export const Section =styled.div`
${tw`pb-36  justify-center `};
& {
  }
`
export const Container =styled.div`
${tw`flex flex-wrap`};
& {
  }`

export const Certificates = styled.div`
${tw`w-full md:w-1/2 lg:w-1/4 px-3  h-16 mb-2 flex justify-center cursor-pointer pointer-events-auto`};
& {
    div{
      ${tw`p-4 bg-white md:w-56 lg:w-full xl:w-56 mx-auto rounded-lg hover:bg-gray-200 hover:border-transparent flex pointer-events-auto`}
    }
    h3{
      ${tw` text-xl font-semibold text-center`}
     
    }
    p{
      ${tw`text-base text-gray-600 flex-shrink pl-4 pointer-events-none truncate`}
    }
    svg{
      ${tw`flex-none`}
    }
  }`

export const FormInput = styled.main`
${tw`mb-4 flex-shrink w-96 mx-5 pointer-events-auto`};
& {
    label{
      ${tw`block text-nothotBlack-500 text-base mb-2 font-bold`};
    }
    input{
      ${tw`pl-3 h-10 appearance-none border rounded-lg w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-md border-nothotBlue-500`};
    }
  }`

export const FormButton = styled.button`
${tw`pointer-events-auto mr-5 flex-none h-10 px-5 py-2 bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500  text-white rounded-lg cursor-pointer`};
& {
  }
`
export const Form = styled.form`
${tw`flex pt-4 relative z-20 justify-center w-full opacity-100` };
& {

  }`


export const FeatureBlock = styled.div`
${tw`flex lg:block relative pb-12 content-center w-full lg:w-1/2 xl:w-1/4`};
& {}`

export const FeatureIcon = styled.div`
${tw`flex justify-center`};
& {
  div{
    ${tw`flex-shrink-0 w-20 h-20 rounded-full inline-flex items-center align-middle justify-center border border-nothotBlue-500`}
    &{
      svg{
        ${tw`w-10 h-10`}
      }
      }
    }
}`

export const FeatureText = styled.main`
${tw`flex-grow pl-4 lg:pl-10 lg:pr-10 pt-0 lg:pt-4 `};
& {
  h1{
    ${tw`font-medium text-lg text-nothotBlack-500 mb-1 tracking-wider text-left lg:text-center uppercase`}
  }
  p{
    ${tw`leading-relaxed text-left lg:text-center text-gray-600`}
  }
}`

export const TextContainer = styled.main`
${tw`w-full translate-y-0 translate-x-0 transform overflow-x-hidden h-auto`};
& {

    h1{
      ${tw`text-3xl mb-8 font-bold leading-7 text-nothotBlack-500  sm:text-5xl text-center `}
    }
    #white{
      ${tw` text-white `}
    }
    p{
      ${tw`mb-16 text-base text-justify lg:text-center lg:px-32 xl:px-40 2xl:px-48 text-gray-600`}
    }
    #quote{
      ${tw`italic`}
    }
  }`

export const ImgContainer = styled.main`
${tw`relative overflow-x-hidden overflow-y-hidden w-full z-40 h-screen/2`};
& {
  img{
    ${tw`relative object-cover w-full overflow-x-hidden top-0 inset-x-0 bottom-0 h-full z-40`} //h-96 
  }
}`