import React,{ useEffect, useState} from 'react';
import {  Link} from "react-router-dom";

import FormatSanityContent from '../scripts/FormatSanityContent.js';

import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url';
import {useContent} from '../hooks/content-context';
import {useLanguage} from '../hooks/language-context';

import {Main,TextContainer,FrameContainer, Frame,EmittentContainer,Emittent} from "../styles/contact_style";
//import Collapsible from 'react-collapsible';
import ContactForm from './ContactForm';

const sanityImageBuilder = imageUrlBuilder(sanityClient);
function getSanityImageUrl(source) {
  return sanityImageBuilder.image(source)
}

function Contact(props) {
//
    const[co2Values, setCO2Values]=useState(0);
    const[co2CategoryValues, setCO2CategoryValues]=useState(0);
    const [sendCO2Data, setSendCO2Data] = useState(true);
    //const [co2, setCo2] = useState(0);
    const {state: {language}} = useLanguage();
    const {state: {contentKontakt,valuesCO2Rechner}} = useContent();

    function checkLocalStorage(storageName,newObject){
        let storedData =  JSON.parse(localStorage.getItem(storageName));

        if(!storedData){
           return newObject;
           }
        return storedData;
    }

    useEffect(()=>{

        setCO2Values(checkLocalStorage('co2Values',{}));
       // setCo2(localStorage.getItem("co2"));
        setCO2CategoryValues(checkLocalStorage('co2Categories',{

          "Abfall": 0,
          "Fahrzeuge": 0,
          "Flüge": 0,
          "Strom": 0,
          "Wasser": 0

        }))
    },[]);

    useEffect(()=>{
      valuesCO2Rechner && valuesCO2Rechner.map((value,index)=>{console.log(value["category"]["de"])})
    },[valuesCO2Rechner])

    function handleSendCO2Data(){
      setSendCO2Data(!sendCO2Data);
    }

    return (
        <Main>
            <TextContainer>
                
          
            <h1> {contentKontakt && FormatSanityContent(contentKontakt[0],language,"headline")}  </h1>    
                <p>
                {contentKontakt && FormatSanityContent(contentKontakt[0],language,"paragraph")}
                
                </p>
                <br/>
                <br/>
                <div>
                <input id="name" type="checkbox" placeholder="Firma" 
                checked={sendCO2Data}
                onChange={handleSendCO2Data}
                >
                </input> 
                  <label> <b>{contentKontakt && FormatSanityContent(contentKontakt[1],language,"animationText")}</b></label>
                  <br/>     
                  <br/>
                </div>

            </TextContainer>
                  <FrameContainer>
                    <Frame  active={sendCO2Data}>
                        <EmittentContainer style={{backgroundColor:"50,50,50"}}>
                        {valuesCO2Rechner && valuesCO2Rechner.map((value,index)=>(

                      <Emittent>
                      <div>
                        <img src={value.icon && getSanityImageUrl(value.icon.asset).width(150).height(150).fit("crop").url()} width="150px" height="150px" alt={co2CategoryValues[value["category"]["de"]]}></img>
                        <p>{Math.floor(co2CategoryValues[value["category"]["de"]])}</p>
                      </div>
                      </Emittent>

                        ))}
                          
                            
                            <Emittent>
                                <div>
                                    <span><Link to="/CO2Rechner">{"Werte bearbeiten"}</Link></span>
                                    
                                </div>
                            </Emittent>

                        </EmittentContainer>
                    </Frame>
                  </FrameContainer>
         <ContactForm co2Values={co2Values} co2CategoryValues={co2CategoryValues}/>

        </Main>
    );
}

export default Contact;