import React,{ useEffect, useRef } from 'react';

import HeadMenu from "../components/HeadMenu";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import ForestCO2Ani from "../components/ForestCO2Ani";
import ForestGrowAni from "../components/ForestGrowAni";
import Benefits from "../components/Benefits";
import ScrollTrigger from 'gsap/ScrollTrigger';
import {useLanguage} from '../hooks/language-context';

function Home(props) {
    const overlayContact=useRef(null);
    const forestGrowRef = useRef(null);
    const forestAniRef = useRef(null);

    const {state: {language}} = useLanguage();

   
    
    useEffect(()=>{
      //Workaround: refresh timeout to prevent trigger misbehavior
      setTimeout(()=>{refreshTrigger()}, 1000);
      
      return function cleanup() {
        while (ScrollTrigger.getAll().length > 0) {
          ScrollTrigger.getAll()[0].kill();
        }
      };
    },[])

   useEffect(()=>{
    refreshTrigger();
   },[language])

   function refreshTrigger(){
    ScrollTrigger.refresh();
   }

  
   
    return (
      <div>

        <div>

            <HeadMenu  />

            <ForestGrowAni viewType={props.viewType} videoLow={props.videoAlow} video={props.videoA}   forwardRef={forestGrowRef}  />

            <Benefits forestGrowRef={forestGrowRef} forestAniRef={forestAniRef}/>
              
            <ForestCO2Ani viewType={props.viewType}  videoLow={props.videoBlow} video={props.videoB}  forestAniRef={forestAniRef} overlayContactRef={overlayContact} />

            <div>
            </div>
                
            <div style={{marginTop:0, zIndex:5}} ref={overlayContact}>
                  <Contact/> 
            </div>
                  <Footer/>
        </div>

      </div>
    );
}

export default Home;
