import tw, { styled } from 'twin.macro';

export const Main =styled.div`
${tw`relative flex flex-wrap bg-white  z-40 justify-center pt-4`};
& {
  h1{
      ${tw`flex-none px-3 sm:px-6 md:pl-12 lg:pl-24  min-w-max text-base text-gray-900 mt-4 `}
    }
    span{
      ${tw`w-full lg:w-max lg:flex-grow text-center lg:text-right  lg:pr-24 self-center h-14 text-base text-gray-900 mt-4`}
    }
  }
`

export const TextContainer = styled.main`
${tw`mx-auto pb-28 w-full translate-y-0 translate-x-0 transform  bg-white overflow-x-hidden`};
& {

    h1{
      ${tw`ml-32 mr-32 mt-12 text-2xl font-bold leading-7 text-gray-900  sm:text-2xl `}
    }
    p{
      ${tw`ml-32 mr-32 mt-4 max-w-7xl text-lg `}
     
    }
  }`

