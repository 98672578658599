import tw, { styled } from 'twin.macro';

export const Main =styled.div`
${tw`bg-gray-50`};
& {
  }
`

export const ImgContainer = styled.main`
${tw`overflow-x-hidden overflow-y-hidden w-full `};
& {
  img{
    ${tw` object-cover w-full overflow-x-hidden top-0 inset-x-0 bottom-0 h-full`} //h-96 
  }
}`

export const Headline =styled.h1`
${tw`mb-12 text-2xl font-bold leading-7 text-nothotBlack-500  sm:text-3xl sm:truncate `};
& {
  }`

export const TextContainer = styled.main`
${tw`mt-12 w-full xl:w-2/3`};
& {
    h1{
      ${tw`mt-12  text-5xl font-bold text-nothotBlack-500`}
    }
    h2{
      ${tw`mt-12 text-2xl font-bold leading-7 text-nothotBlack-500  sm:text-2xl `}
    }
    p{
      ${tw`mt-6 text-base text-gray-600 `}
     
    }&
    {
      a{
        ${tw`text-nothotPink-500 font-bold`}
      }
    }
  }`

export const PostHead = styled.main`
${tw`w-full h-auto xl:w-1/3 mt-12 `};
  &   
  span{
  ${tw`mr-4 flex  w-full rounded-lg  mt-8`}
  }&
    {
      div{
        ${tw`py-6 px-4 flex-grow flex-wrap w-3/4 `}
      }&
      {
        h2{
          ${tw` text-lg font-bold leading-7 text-nothotBlack-500  sm:text-lg text-right uppercase w-full`}
        }
        p{
          ${tw` text-base  text-right text-gray-500 w-full`}
        }
        #position {
          ${tw`text-sm `}
        }
      }& img{
        ${tw`mr-8 w-14 h-14 mt-6 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100 flex-shrink`}
      }
    }`

export const Section =styled.div`
${tw`pt-14 pb-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex flex-wrap bg-white`};
& {    h1{
      ${tw`mt-12  text-5xl font-bold text-nothotBlack-500 w-full`}
    }
  }`

export const SectionGray =styled.div`
${tw`py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex flex-wrap bg-gray-50 `};
& {
  }`

export const Container =styled.div`
${tw`flex flex-wrap `};
& {
  h1{
    ${tw`ml-4 mt-8 text-2xl font-bold leading-7 text-nothotBlack-500 sm:text-3xl sm:truncate`}
  }
  }`

export const Card = styled.div`
${tw`p-4 md:w-1/2 xl:w-1/3 sm:mb-0 mb-6`};
& {
    h2{
      ${tw`text-xl font-medium text-nothotBlack-500 mt-5`}
    }
    p{
      ${tw`text-base leading-relaxed mt-2 h-20 overflow-hidden line-clamp-3`}
    }  
    a{
      ${tw`text-nothotBlue-500 inline-flex items-center mt-3`}&
        svg{
          ${tw`w-4 h-4 ml-2`}
      }
    }
    div{
      ${tw`rounded-lg h-64 overflow-hidden`}& {
        img{
          ${tw`object-cover object-center h-full w-full`}
        }
      }
    }
    
  }`
