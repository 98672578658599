import React, {useRef,useState,useEffect} from 'react';
import sanityClient from "../client.js";
import FormatSanityContent from '../scripts/FormatSanityContent.js';
import {useContent} from '../hooks/content-context';
import {useLanguage} from '../hooks/language-context';

import {useParams } from "react-router-dom"; //useLocation
import { SectionTranparent, Main,Map,MapSpace,Certificates,Section,Container,TextContainer,MapCopyright,FormInput,FormButton,Form } from "../styles/findtree_style";
import HeadMenu from "./HeadMenu";
import Footer from "../components/Footer";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

 // eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1Ijoic3VwcG9ydGVyIiwiYSI6ImNrdmkxcmRuNDAxYTkyb252OWM1dGYwdzcifQ.BQXg--yl1OBvnzhFt95B7A';


function FindTree(props) {
    const { id } = useParams();
    const { width } = useWindowDimensions();
    const [allCerts, setAllCerts] = useState(null);
    const [map, setMap] = useState(null);
    const [nrCertsToShow,setnrCertsToShow] =useState(0);
    const [searchCertNr,setSearchCert]=useState(0);
  //  const [mapMarker, setMapMarker] = useState(null);
  //  const [zoom, setZoom] = useState(5);

    const mapContainer = useRef();

    const start = [9.732273577692233, 52.37633862264693];
    const imgCanvasAni01 = useRef(null);
    const mainPin = useRef(null);
    //const mapCanvasAni01 = useRef(null);
    const mapPin = useRef(null);
    const searchBar = useRef(null);
    const mapBottomPin = useRef(null);
    const certificatesPin = useRef(null);
    const mapboxCopyright = useRef(null);
    const certificatesDiv = useRef(null);
    const stickyBar = useRef(null);
    
    const {state: {language}} = useLanguage();
    const {state: {contentZertifikate}} = useContent();
    useEffect(()=>{
        window.scrollTo(0, 0);}
        ,[]);
   useEffect(()=>{
    ScrollTrigger.refresh();
    //blockScroll();
   },[language])

   useEffect(()=>{
       if (width<640)    setnrCertsToShow(2);
       else setnrCertsToShow(4);
    
   },[width])

   useEffect(()=>{
    ScrollTrigger.refresh();

    },[nrCertsToShow])
   
   useEffect(()=>{
    return function cleanup() {
      while (ScrollTrigger.getAll().length > 0) {

        ScrollTrigger.getAll()[0].kill();
      }
    };
  },[])
         

      

    useEffect(() => {

        if (mapContainer && certificatesPin && stickyBar && searchBar && mapContainer && imgCanvasAni01) {
            
            let tl = gsap.timeline();

            gsap.to(imgCanvasAni01.current, {
                y: 200,
                scrollTrigger: {
                    trigger: mainPin.current,
                    scrub: 0,
                    start: () => 'top top',
                    end: () => '+=100%',
                    markers: false
                }
            });

            gsap.to(mapContainer.current, {
                y: -384,
                scrollTrigger: {
                    trigger: mapPin.current,
                    scrub: 0,
                    start: () => 'bottom bottom',
                    end: () => '+=380%',
                }
            });

            gsap.to(searchBar.current, {
                y: -100,
                scrollTrigger: {
                    trigger: mapPin.current,
                    scrub: 0.6,
                    start: () => 'bottom+=200px top',
                    end: () => '+=1px',
                    markers: false,
                }
            });

            ScrollTrigger.create({
                pin: stickyBar.current,
                trigger: mapBottomPin.current,
                pinSpacing: true,
                start: () => 'top bottom',
                end: () => 'bottom bottom'
            }
            )

            gsap.to(certificatesPin.current, {
                scrollTrigger: {
                    pin: certificatesDiv.current,
                    trigger: certificatesPin.current,
                    pinSpacing: true,
                    start: () => 'bottom top+=130px',
                    end: () => '+=300px'
                }
            });

            gsap.to(mapContainer.current, {
                opacity: 1,
                scrollTrigger: {
                    trigger: mapBottomPin.current,
                    scrub: 0,
                    start: () => 'top-=0 bottom',
                    end: () => '+=50%',
                    markers: false
                }
            });

            gsap.to(mapboxCopyright.current, {
                opacity: 1,
                scrollTrigger: {
                    trigger: mapBottomPin.current,
                    scrub: 0,
                    start: () => 'top-=0 bottom',
                    end: () => '+=50%',
                    markers: false
                }
            });
            
            /*
                gsap.to(certificatesDiv.current, { 
                    y: -500,
                    scrollTrigger: {
                    trigger: mapPin.current,
                    scrub:0.5,
                    start: () => 'bottom top',
                    end: () => '+=200px',
                    markers: false
                    }
                });*/
        }
    }, [mapContainer, certificatesPin, stickyBar, searchBar, imgCanvasAni01])






function flyTo(lat,lng){
   
    
    certificatesPin.current.scrollIntoView({ behavior: 'smooth' });

   map.flyTo({
        center: [lat,lng],
        zoom: 16,
        bearing: 0,
        speed: 0.35, 
        curve: 1.42, // change the speed at which it zooms out
        // This can be any easing function: it takes a number between
        // 0 and 1 and returns another number between 0 and 1.
        easing: function (t) {
        return t;
        },
        // this animation is considered essential with respect to prefers-reduced-motion
        essential: true
        })
    map.on('moveend', function(e){
       // console.log("FLYEND");
/*
      for(let i=0; i<allCerts.length;i++){
        let m = new mapboxgl.Marker({color: 'black', offset: [0, 0]});
        m.setLngLat([allCerts[i].location.lat,allCerts[i].location.lng]);
        m.addTo(map);   
    } */
     });
}

function formatDateString(datestring){
    let newdate=new Date(datestring);
    return newdate.toLocaleDateString();
}

function onCertInputChange(e){
    setSearchCert(e.target.value);

}
function searchCert(e){
    e.preventDefault();

    allCerts.map((cert, index)=>{
        if(searchCertNr===cert.id){
            flyTo(cert.location.lng,cert.location.lat)
        }
    })

}

 //SANITY
 useEffect(() => {
    sanityClient.fetch(
      /* groq */`*[_type == "certificates"] | order(date){
        _id,
        id,
        location,
        certdate
  }`).then((data) => {
    setAllCerts(data)
    }).catch(console.error);
}, []);

//MAPBOX
useEffect(() => {
    if(allCerts)
    {
    var map= new mapboxgl.Map({
    container: mapContainer.current,
    style: 'mapbox://styles/supporter/ckvi1gfej5ndz14qxjddpdmkz',
    attributionControl: false,
    center: start,
    zoom: 10
    });
    map.scrollZoom.disable();
    map.dragPan.disable();

/*
for(let i=0; i<allCerts.length;i++){
    let m = new mapboxgl.Marker({color: 'black', offset: [0, 0]});
    m.setLngLat([allCerts[i].location.lat,allCerts[i].location.lng]);
    m.addTo(map);   
} */
/**
    map.on('render', function() {
    
    });
    */  
    setMap(map);
    //map.addControl(new mapboxgl.AttributionControl(), 'top-left');

    return () => map.remove();
}
    }, [allCerts]);

    useEffect(()=>{

        if(id && map)
        {
        flyTo(allCerts[0].location.lng,allCerts[0].location.lat); //TODO fly to specific id
        //flyto...
    
        }
    },[map,id])


    return (
        <Main ref={mainPin}>
            <HeadMenu />

            <Map ref={mapContainer}> </Map>

            <div ref={mapPin}></div >
            
            <SectionTranparent >

                <div >
                <MapCopyright ref={mapboxCopyright}>© Mapbox © OpenStreetMap Improve this map © Maxar</MapCopyright>
                    <TextContainer>
                    <h1 id="white">{contentZertifikate && FormatSanityContent(contentZertifikate[1],language,"headline")}</h1>
                    <p id="white">{contentZertifikate && FormatSanityContent(contentZertifikate[1],language,"paragraph")} </p>
                    </TextContainer>
                    <div ref={certificatesPin}></div>
                    <Section>
                        <Container ref={certificatesDiv}>
                        {allCerts && allCerts.map((cert, index)=>(
                            <React.Fragment key={cert._id}>
                                {index < nrCertsToShow &&
                            <Certificates key={cert._id} onClick={()=>flyTo(cert.location.lng,cert.location.lat)}>
                              
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-patch-check" viewBox="0 0 16 16"><path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/></svg>
                                    <p> 
                                    {cert.id}
                                    </p>
                                </div>
                            </Certificates>
                            }
                            </React.Fragment>
                            ))
                        }
                        </Container>
                    
                    </Section>
                </div>
            </SectionTranparent>
                <MapSpace ref={mapBottomPin}>
                <div ref={stickyBar}>
                    <Form ref={searchBar} onSubmit={searchCert}>
                        <FormInput >
                                <input id="name" type="text" placeholder="Zertifikatsnummer"  onChange={onCertInputChange}></input>
                        </FormInput> 

                        <FormButton>suchen</FormButton>                                 


                    </Form>                
                </div>     
                </MapSpace>


 

<Footer/>
        </Main>
    );
}

export default FindTree;