import React from 'react';
import HeadMenu from "./HeadMenu";
import Footer from "../components/Footer";
import {useLanguage} from '../hooks/language-context';
import {TextContainer,SectionWhite,ImgContainer} from "../styles/contact_style";

function FormSend(props) {
    return (
        <div>
            <HeadMenu/>

            <ImgContainer>
                <img alt="headerImage" src="https://assets.manufactum.de/c/manufactum/journal/heilpflanzen/birke-heilpflanze.jpg?profile=kurator_1250"></img>
            </ImgContainer>

            <SectionWhite>
          
            <TextContainer >
            <h1>Formular gesendet</h1>
            <p>Schön das Sie uns gefunden haben. Ihre Anfrage wurde erfolgreich übermittelt. Wir melden uns in den kommenden Werktagen bei Ihnen.
        </p>
        </TextContainer>
        </SectionWhite>
        <Footer/>
        </div>
    );
}

export default FormSend;