import React, {useEffect} from 'react';
import {  Link} from "react-router-dom"; 

import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url';
import HeadMenu from "./HeadMenu";

import { ImgContainer, TextContainer, Main, Section,Card,Container,SectionGray,Headline,PostHead } from "../styles/klingelton_stlye";
import Footer from "../components/Footer";

import {useContent} from '../hooks/content-context';
import BlockContent from '@sanity/block-content-to-react';

import birchshelteriOS from '../files/birchshelter_120bpm_m4a_30sec.m4r'
import birchshelterAndroid from '../files/birchshelter_120bpm_mp3_30sec.mp3'

const sanityImageBuilder = imageUrlBuilder(sanityClient);

function getSanityImageUrl(source) {
    return sanityImageBuilder.image(source)
}

const getUrlFromId = ref => {
    // Example ref: file-207fd9951e759130053d37cf0a558ffe84ddd1c9-mp3
    // We don't need the first part, unless we're using the same function for files and images
    const [_file, id, extension] = ref.split('-');
    return `https://cdn.sanity.io/files/r352z1oe/production/${id}.${extension}`
  
  }

const PostDetails = (props) => {

    const {state: {contentPosts,contentKlingelton}} = useContent();
const render=false;
    useEffect(()=>{
        window.scrollTo(0, 0);}
        ,[]);


    return (
        <Main>

            <HeadMenu allPosts={contentPosts} />
            <ImgContainer>
                <img alt="background" src={contentKlingelton && getSanityImageUrl(contentKlingelton[0].asset).width(1500).height(500).url()}></img>
            </ImgContainer>
            <Section>                

                <TextContainer>
                    <h1>{contentKlingelton && contentKlingelton[1].headline["de"]}</h1>

                    {<BlockContent blocks={contentKlingelton ? contentKlingelton[1].paragraph["de"]:[]}/>} 
<p><a target="_blank" download="notHotBirchShelter.m4r" href={birchshelteriOS} > Klingelton für iOS (iPhone...) herunterladen </a></p>
<p><a target="_blank" download="notHotBirchShelter.mp4" href={birchshelterAndroid} > Klingelton für Android herunterladen </a></p>
                </TextContainer>

            </Section>

            <SectionGray>
            <TextContainer>
               <h1> Anleitungen</h1>
        <h2>Klingelton installieren iPhone: </h2>
        <p>
    Verbinden Sie das iPhone mit Ihrem Mac und öffnen Sie den iPhone-Tab im Finder. <br/>
    Oben befindet sich eine Leiste mit Informationen über Ihr iPhone.  <br/>
    Ziehen Sie die m4r-Datei auf diese Leiste, nicht auf aber neben das iPhone-Symbol (siehe Bild).  <br/>
    Jetzt ist der Song auf Ihr iPhone importiert und lässt sich in den Klingelton-Einstellungen aktivieren.</p>
    <br/>

    <img alt="iphoneinstallation" src={contentKlingelton && getSanityImageUrl(contentKlingelton[4].asset).width(400).height(350).url()}></img>

    <h2>Klingelton installieren Android: </h2>
        <p>
            

    Öffnen Sie auf Ihrem Smartphone die Einstellungen.<br/>
    Tippen Sie auf Ton & Vibration. Klingelton.<br/>
    Wählen Sie einen Klingelton aus.<br/>
    Tippen Sie auf Speichern.

</p>

</TextContainer>
            </SectionGray>
            <Footer />


        </Main>
    );
};

export default PostDetails; 