import React from 'react';
import { CookieBanner } from '@palmabit/react-cookie-law';
import {useLanguage} from '../hooks/language-context';
import {useContent} from '../hooks/content-context';

function CookieConsent(props) {

    const {state: {language}} = useLanguage();
    const {state: {contentCookieConsent}} = useContent();

    return (
        <React.Fragment>
             <CookieBanner
      message={contentCookieConsent && contentCookieConsent[language]}
      acceptButtonText="Akzeptieren"
      managePreferencesButtonText="Details"
      policyLink="/Datenschutz"
      wholeDomain={true}
      onAccept = {() => {}}
      onAcceptPreferences = {() => {}}
      onAcceptStatistics = {() => {}}
      onAcceptMarketing = {() => {}}
      styles={{
        dialog: { position:"fixed",zIndex:1000,left:0,right:0,padding:"10px",bottom:0, backgroundColor:"rgba(248,247,247,.8)"}
      }}
    />
        </React.Fragment>
    );
}

export default CookieConsent;