import React,{useEffect,useContext} from 'react';
import {useContent} from '../hooks/content-context';
import sanityClient from "../client.js";

function Content(props) {

    const {dispatchContent} = useContent();
    const {state: {content}} = useContent();

    function addContent(contentData){
        dispatchContent({ type:"addData", contentData });
      }

//GET SANITY CONTENT
 useEffect(() => {

   ////////////// PAGES
    sanityClient.fetch(
      /* groq */`*[_type == "pages"] | order(identifier){
      _id,
      identifier,
      pageElement
  }`).then((data) => {
    //Process sanity Content
    var homeElements=[];
    var zertifikateElements=[];
    var kontaktElements=[];
var co2RechnerElements=[];
var beratungElements=[];
var animationWaldWachsen=[];
var animationWaldCO2=[];
var klingeltonElements=[];

    for (let i=0;i<data.length;i++){
  
      switch (data[i]["identifier"]){
        case "Home":       data[i]["pageElement"].forEach(element => homeElements.push(element));
                           addContent({"contentHome":homeElements});
                           break;
        case "AnimationWaldwachsen":       data[i]["pageElement"].forEach(element => animationWaldWachsen.push(element));
                                      addContent({"contentWaldWachsen":animationWaldWachsen});
                                      break;
        case "AnimationWaldCO2":       data[i]["pageElement"].forEach(element => animationWaldCO2.push(element));
                            addContent({"contentWaldCO2":animationWaldCO2});
                            break;
        case "Pflanzgebiete": data[i]["pageElement"].forEach(element => zertifikateElements.push(element));
                            addContent({"contentZertifikate":zertifikateElements});
                            break;

        case "Kontakt":     data[i]["pageElement"].forEach(element => kontaktElements.push(element));
                            addContent({"contentKontakt":kontaktElements});
                                break;
        case "CO2Rechner":     data[i]["pageElement"].forEach(element => co2RechnerElements.push(element));
                              addContent({"contentCO2Rechner":co2RechnerElements});
                           break;
        case "Beratung":     data[i]["pageElement"].forEach(element => beratungElements.push(element));
                                addContent({"contentBeratung":beratungElements});
                            break;
        case "Klingelton":     data[i]["pageElement"].forEach(element => klingeltonElements.push(element));
        addContent({"contentKlingelton":klingeltonElements});
                        break;
        default: break;
      } 
    }
    }).catch(console.error);


    ////////////// TESTIMONIALS
    sanityClient.fetch(
      /* groq */`*[_type == "testimonials"] | order(identifier){
      _id,
      identifier,
      testimonial
  }`).then((data) => {
    //Process sanity Content
    var testimonialElements=[];
    data[0]["testimonial"].forEach(element => testimonialElements.push(element));
    addContent({"contentTestimonials":testimonialElements});
    }).catch(console.error);



    ////CO2 RECHNER

    sanityClient.fetch(
      /* groq */`*[_type == "co2Calculator"] | order(id){
      id,
      category,
      text,
      categoryValues,
      valueType,
      sliderMax,
      icon
  }`).then((data) => {

    addContent({"valuesCO2Rechner":data});
    }).catch(console.error);


     ////////////// CO2 RECHNER GLOBAL VALUE
     sanityClient.fetch(
      /* groq */`*[_type == "globalValues"] | order(id){
      id,
      co2toha
  }`).then((data) => {
    //Process sanity Content
    addContent({"globalValues":data});
    }).catch(console.error);


      sanityClient.fetch(
        /* groq */`*[_type == "post"] | order(identifier){
        _id,
        identifier,
        slug,
        title,
        mainImage,
        publishedAt,
        body,
        authorName,
        authorPosition,
        authorImage
    }`).then((data) => {
      addContent({"contentPosts":data})
     
      }).catch(console.error);
  
      sanityClient.fetch(
        /* groq */`*[_type == "legalTexts"] | order(id){
        title,
        textDE,
        textEN,
        textLV
    }`).then((data) => {
      
      let cookieTexts={
        "de":data.find(x => x.title === "CookieBanner").textDE[0]["children"][0].text,
        "en":data.find(x => x.title === "CookieBanner").textEN[0]["children"][0].text,
        "lv":data.find(x => x.title === "CookieBanner").textLV[0]["children"][0].text
      }
    
      
      addContent({"contentCookieConsent":cookieTexts})
      }).catch(console.error);
  
  }, []);
     
    return (
        <React.Fragment>
            
        </React.Fragment>
    );
}

export default Content;