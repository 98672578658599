import React from 'react';
import ContactForm from "./ContactForm";


import { FlexContainer,HeadLine,UnderLine  } from "../styles/emissionSlider_style";

function EmissionFormular(props) {
    
    return (
        <React.Fragment>

           <HeadLine> <br/> Kontakt </HeadLine>

           <UnderLine> Senden Sie uns Ihre Emissionsdaten für ein erstes, kostenloses Beratungsgespräch.</UnderLine>
            <FlexContainer>
                <ContactForm co2Values={props.co2Values} co2CategoryValues={props.co2CategoryValues}/>
            </FlexContainer>
            

        </React.Fragment>
        
        );
}

export default EmissionFormular;