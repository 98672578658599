import tw, { styled } from 'twin.macro';

export const Main =styled.div`
${tw`bg-yellow-50 min-h-screen`};
& {
  }
`
export const SectionGradient = styled.div`
${tw`py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500 w-full h-full`};
&  {

}` 

export const BoxGradient = styled.div`
${tw`py-20 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 m-auto bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500 w-full h-full`};
&  {

}` 

export const SectionWhite = styled.div`
${tw`relative  pt-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex bg-white flex-wrap shadow-inner z-40`};
& {}`

export const SectionGray = styled.div`
${tw`relative  py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex flex-wrap bg-gray-100 shadow-inner z-40`};
& {}`

export const TextContainer = styled.main`
${tw`mx-auto  w-full translate-y-0 translate-x-0 transform overflow-x-hidden h-auto `};
& {

    h1{
      ${tw`mt-12 text-2xl font-bold leading-7   sm:text-3xl sm:truncate`}
    }
    p{
      ${tw`mt-4 mb-20 max-w-7xl text-base `}
     
    }
  }`

export const SectionWrapper = styled.div`
${tw`flex flex-wrap w-full h-screen/2 lg:h-screen/2 justify-center content-center overflow-hidden`};
& {}`

export const Target = styled.div`
${tw``};
& {}`

export const StepBlock = styled.div`
${tw`block md:py-16 lg:pb-0 w-full m-auto absolute opacity-0 overflow-hidden`};
& {}`

export const StepText = styled.main`
${tw`py-4  justify-center content-center`};
& {
  h2{
    ${tw`font-medium text-white mb-1 tracking-wider text-center uppercase text-sm `}
  }
  p{
    ${tw`leading-relaxed text-center text-white break-words italic text-sm w-5/6   sm:w-3/4 lg:text-xl lg:w-1/2 m-auto opacity-75 pb-6`}
  }
  
  

}`

export const FeatureIcon = styled.div`
${tw`flex justify-center`};
& {
  div{
    ${tw`flex-shrink-0 w-20 h-20 lg:w-52 lg:h-52 rounded-full inline-flex items-center align-middle justify-center relative z-10`}
    &{
      img{
        ${tw`w-20 lg:w-52 `}
      }
      }
    }
}`

