import React,{ useEffect, useRef } from 'react';

import ScrollVideo from "./ScrollVideo";
import {TextOverlay,ImgCanvas,SlideCenter,SlideTop,SlideBottom} from "../styles/forestCO2Ani_style";
import gsap from 'gsap';

import {useLanguage} from '../hooks/language-context';
import {useContent} from '../hooks/content-context';
import FormatSanityContent from '../scripts/FormatSanityContent.js';
import { useWindowDimensions } from '../hooks/useWindowDimensions';





function ForestGrowAni(props) {


    const birchGrowAniLength=1600; //Pixel
    const { width } = useWindowDimensions();

    const {state: {language}} = useLanguage();
    const {state: {contentWaldWachsen}} = useContent();

    const overlay1Head1 = useRef(null);
    const overlay1HeadArrow = useRef(null);
    const overlay1Sub1=useRef(null);
    const overlay1Sub2=useRef(null);
const overlay1Top=useRef(null);

    useEffect(() => {
        if (overlay1Head1 && overlay1Sub1 && overlay1Sub2 && overlay1Top) {
      
     /////////////////////////////////////////////////////////
     const birchGrowTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: props.forwardRef.current,
          start: () => 'top',
          end: () => width<768?'3200px':'3500px',
          scrub: true,
          markers: false
        }
      });
      
      birchGrowTimeline.to(overlay1Top.current, { opacity: 0, duration: 2 });

      birchGrowTimeline.to(overlay1Head1.current, { y: -200, opacity: 0, duration: 2 }, "-=1.5");
      birchGrowTimeline.to(overlay1HeadArrow.current, { y: 200, opacity: 0, duration: 2 }, "<");

      birchGrowTimeline.fromTo(overlay1Sub1.current, { y: 300, opacity: 0 }, { y: 0, opacity: 1, duration: 2 });
      birchGrowTimeline.fromTo(overlay1Sub1.current, { y: 0, opacity: 1 }, { y: -300, opacity: 0, duration: 2, immediateRender: false }, "+=2",);

      birchGrowTimeline.fromTo(overlay1Sub2.current, { y: 300, opacity: 0 }, { y: 0, opacity: 1, duration: 2 });
      birchGrowTimeline.fromTo(overlay1Sub2.current, { opacity: 1 }, { opacity: 1, duration: 2, immediateRender: false }, "+=2");
     //birchGrowTimeline.fromTo(props.forwardRef.current, { y: 0 }, { y: +200, duration: 4, immediateRender: false }, "-=4");
    }
    }, [overlay1Head1,overlay1Sub1,overlay1Sub2,overlay1Top]);

    return (
<div className="topPin" style={{zIndex:"0"}}>
            
            <ImgCanvas ref={props.forwardRef} >      
              <ScrollVideo  viewType={props.viewType} videoLow={props.videoLow} video={props.video}  fps={30} frames={66}  offsetTop="104" offsetX={.8} length={birchGrowAniLength} pinlength={birchGrowAniLength} container={props.forwardRef} pin=".topPin" />
              <TextOverlay >
                <SlideTop><h1 ref={overlay1Top}>{contentWaldWachsen && FormatSanityContent(contentWaldWachsen[0],language,"animationText")}</h1></SlideTop>
                <SlideCenter><div></div></SlideCenter>
                <SlideBottom>
                  <div>

                  <h1 ref={overlay1Head1}> {contentWaldWachsen && FormatSanityContent(contentWaldWachsen[1],language,"animationText")}</h1> <br/>
                  <svg ref ={overlay1HeadArrow} xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" fill="none" viewBox="0 0 24 24" stroke="#333333"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" /></svg>                 
                </div>
                </SlideBottom>

                <SlideCenter>

                  <h1 ref={overlay1Sub1}>   {contentWaldWachsen && FormatSanityContent(contentWaldWachsen[2],language,"animationText")} </h1>            
                </SlideCenter>
                <SlideCenter>
                  <h1 ref={overlay1Sub2}>  <mark> {contentWaldWachsen && FormatSanityContent(contentWaldWachsen[3],language,"animationText")} </mark></h1>             
                </SlideCenter>

            </TextOverlay>

            </ImgCanvas>
            </div>
    );
}

export default ForestGrowAni;