import * as React from 'react'

const LanguageContext = React.createContext()

function languageReducer(state, action) {
  console.log("LANGUAGEREDUCER");
  console.log(action);
  switch (action.type) {
    case 'changeLanguage': {
      return {language: action.language}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function LanguageProvider({children}) {
  const [state, dispatchLanguage] = React.useReducer(languageReducer, {language: "de"})
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatchLanguage}
  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}

function useLanguage() {
  const context = React.useContext(LanguageContext)
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}

export {LanguageProvider, useLanguage}