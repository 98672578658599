import React, {useEffect} from 'react';
import {  Link} from "react-router-dom"; 

import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url';
import HeadMenu from "./HeadMenu";

import { ImgContainer, TextContainer, Main, Section,Card,Container,SectionGray,Headline,PostHead } from "../styles/postDetails_style";
import Footer from "../components/Footer";

import {useContent} from '../hooks/content-context';
const sanityImageBuilder = imageUrlBuilder(sanityClient);

function getSanityImageUrl(source) {
    return sanityImageBuilder.image(source)
}

const PostDetails = (props) => {

    const {state: {contentPosts}} = useContent();

    useEffect(()=>{
        window.scrollTo(0, 0);}
        ,[]);

    return (
        <Main>

            <HeadMenu allPosts={contentPosts} />
            <ImgContainer>
                <img alt="background" src={getSanityImageUrl(props.postData.mainImage.asset).width(1500).height(500).focalPoint(props.postData.mainImage.hotspot.x, props.postData.mainImage.hotspot.y).fit("crop").crop("focalpoint").url()}></img>
            </ImgContainer>
            <Section>

                <PostHead>
                    <span>                        
                        <div>
                            <h2>{props.postData.authorName}</h2>
                            <p id="position">{props.postData.authorPosition}</p><br/>
                        </div>
                        <img alt="author" src={getSanityImageUrl(props.postData.authorImage.asset).width(100).height(100).focalPoint(props.postData.authorImage.hotspot.x, props.postData.authorImage.hotspot.y).fit("crop").crop("focalpoint").url()}></img>
                    </span>
                </PostHead>                

                <TextContainer>
                    <h1>{props.postData.title}</h1>
                    {props.postData.body.map((text, index) =>
                        text.style === "h1" ?
                            <h2 key={"post_h2"+index}>{text.children[0].text}</h2>
                            :
                            <p key={"post_p"+index}>{text.children[0].text}</p>
                    )}

                </TextContainer>

            </Section>

            <SectionGray>
                <Headline>Weitere Beiträge</Headline>
                <Container>

                    {
                        contentPosts.map((value, index) =>
                            <Card key={value._id}>
                                <div>
                                    <img alt="postImage" src={getSanityImageUrl(value.mainImage.asset).width(400).height(400).focalPoint(value.mainImage.hotspot.x, value.mainImage.hotspot.y).fit("crop").crop("focalpoint").url()}></img>
                                </div>
                                <h2>{value.title}</h2>
                                <p>{value.body.map((text, index) =>
                        text.style === "h1" ?
                            <h2 key={"post_h2"+index}>{text.children[0].text}</h2>
                            :
                            <p key={"post_p"+index}>{text.children[0].text}</p>
                    )}</p>
                                <Link to={"/" + value.slug.current}> weiterlesen<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"><path d="M5 12h14M12 5l7 7-7 7"></path></svg>
                                </Link>

                            </Card>
                        )
                    }

                </Container>
            </SectionGray>
            <Footer />


        </Main>
    );
};

export default PostDetails; 