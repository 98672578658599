import tw, { styled } from 'twin.macro';


export const TextContainer = styled.main`
${tw`w-full translate-y-0 translate-x-0 transform  h-auto`};
& {

    h1{
      ${tw`text-3xl mb-8 font-bold leading-7 text-nothotBlack-500  sm:text-5xl sm:truncate text-center `}
    }
    p{
      ${tw`mb-16 text-base text-justify lg:text-center lg:px-32 xl:px-40 2xl:px-48 text-gray-600`}
    }
    #quote{
      ${tw`italic`}
    }
    #justify{
      ${tw`text-justify`}
    }
  }`



export const ImgGallery = styled.main`
${tw`mx-auto  w-full overflow-x-hidden h-auto`};
& {

  div{
    ${tw` w-full h-auto overflow-x-hidden  flex flex-col lg:flex-row`}
    &{
      div{
      ${tw`text-2xl font-bold leading-7 text-gray-900  sm:text-3xl pb-6 lg:p-3`}
        &{
          img{ 
          ${tw`object-cover	 max-h-96 w-full rounded-lg`}
        }
        }
      }
    }
  }
}`


export const Buttons = styled.div`
${tw`flex flex-row flex-wrap justify-center items-center pt-4 `};
& {
  #gradient{
    ${tw`h-10 px-5 bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500  text-white rounded-lg m-1 whitespace-nowrap `}
  }
  #outlined{
    ${tw`h-10 px-5 border border-nothotBlue-500 text-nothotBlue-500 rounded-lg m-1`}
  }
}`

export const MiddlePart = styled.div`
${tw`relative z-40`};
& {}`

export const SectionWhite = styled.div`
${tw`relative  py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex bg-white flex-wrap shadow-inner z-40`};
& {}`

export const SectionGray = styled.div`
${tw`relative  py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex flex-wrap bg-gray-100 shadow-inner z-40`};
& {}`

export const SectionWrapper = styled.div`
${tw`block  lg:flex flex-wrap w-full`};
& {}`

export const ScrollVideoContainer = styled.main`
${tw`overflow-x-hidden overflow-y-hidden h-screen w-full`};
& {
  video{
    ${tw`w-full h-full object-cover`}
  }
}`


export const Color1 = styled.div`
${tw` bg-nothotRed-500 `}`
export const Color2 = styled.div`
${tw` bg-nothotPink-500 `}`
export const Color3 = styled.div`
${tw` bg-nothotBlue-500 `}`


const Vstyles = { standard: tw`w-full h-full object-bottom object-cover`, blur: tw`w-full h-full filter blur-sm object-bottom object-cover` }


export const ImgCanvas = styled.main`
${tw` transform overflow-x-hidden overflow-y-hidden h-95vh`};
& {
  canvas{
    ${tw`overflow-x-hidden inset-x-0 top-0`} //object-contain w-full h-full overflow-x-hidden  inset-x-0 top-0
  }
  video{
    ${tw`w-full h-full object-bottom object-cover
   
    `}
  }
}`

export const TextOverlay = styled.main`
${tw`h-full w-full overflow-x-hidden lg:h-full`};
& {
    h1{
      ${tw`text-2xl font-bold text-white w-10/12 lg:w-1/2 md:text-4xl lg:text-5xl`};
      &{
        mark{
          ${tw`bg-nothotBlack-500 bg-opacity-30 text-white p-0 leading-4 md:leading-11 lg:leading-12 `};
        }
      }
    }
    svg{
      ${tw`inline-block `};
    }
}`


export const SlideTop = styled.div`
${tw`absolute inset-0 flex justify-center items-center text-center`};
& {
  #blendedGrading{
    ${tw`bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500 w-full h-full`}
  }
  h1{
      ${tw`pb-96 w-9/12`};
    }
}`

export const SlideBottom = styled.div`
${tw`absolute shadow-2xl inset-0 flex justify-center items-end text-center pb-64`};
& {
  h1{
      ${tw`w-full`};
    }
}`

export const SlideCenter = styled.div`
${tw`absolute inset-0 flex justify-center items-center text-center`};
& {
  #blendedGrading{
    ${tw`bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500 w-full h-full`}
  }
  h1{
      ${tw`opacity-0`};
    }
}`

export const Grid = styled.div`
${tw`absolute inset-0 w-full h-full z-40 `};
& {
  div{
    ${tw`border-0 border-blue-900`}
  }
  #line{
    ${tw`h-1/6 flex overflow-hidden`}
  }
  #thinline{
    ${tw`h-1/12 flex overflow-hidden`}
  }
  #filler{
    ${tw`h-full w-1/6 flex-shrink-0`}
  }
  #bigfiller{
    ${tw`h-full w-2/6 flex-shrink-0`}
  }
  #halffiller{
    ${tw`h-full w-2/4 flex-shrink-0`}
  }
  #halfleftfiller{
    ${tw`h-full w-5/12 flex-shrink-0`}
  }

  #callout{
      ${tw`h-full flex-shrink overflow-hidden w-96`};
    }
  #label{
      ${tw`flex-grow self-center px-4`};
    }
  svg{
    ${tw`h-full`}
  }
  h2{
    ${tw`text-4xl font-bold self-center text-left text-white whitespace-nowrap`}
  }
  p{
    ${tw`text-left text-white whitespace-nowrap`}
  }
  mark{
          ${tw`bg-nothotBlack-500 bg-opacity-30 text-white p-0 leading-4 md:leading-11 lg:leading-12 `};
  }

}`



export const LottieContainer = styled.main`
${tw`mx-auto w-1/2 transform  overflow-x-hidden h-screen/2`};
& {}`


export const ImgContainer = styled.main`
${tw`overflow-x-hidden overflow-y-hidden w-screen h-screen`};
& {
  img{
    ${tw` w-full h-full overflow-x-hidden top-0 inset-x-0 bottom-0`}
  }
}`

export const FullWidthSpacer = styled.main`
${tw`mx-auto w-1/2 transform  overflow-x-hidden h-screen/2 bg-green-50`};
& {}`