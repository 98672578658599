import { createGlobalStyle } from 'styled-components';

import cerapro_4n_eot from './cerapro_4n.eot';
import cerapro_4n_woff from './cerapro_4n.woff';
import cerapro_4n_woff2 from './cerapro_4n.woff2';
import cerapro_4n_ttf from './cerapro_4n.ttf';

import cerapro_5n_eot from './cerapro_5n.eot';
import cerapro_5n_woff from './cerapro_5n.woff';
import cerapro_5n_woff2 from './cerapro_5n.woff2';
import cerapro_5n_ttf from './cerapro_5n.ttf';

import cerapro_7n_eot from './cerapro_7n.eot';
import cerapro_7n_woff from './cerapro_7n.woff';
import cerapro_7n_woff2 from './cerapro_7n.woff2';
import cerapro_7n_ttf from './cerapro_7n.ttf';

export default createGlobalStyle`

    @font-face {
        font-family: "Cera Pro";
        font-weight: 400;
        font-style: normal;
        src: url(${cerapro_4n_eot}); src: url(${cerapro_4n_eot}?#iefix) format("embedded-opentype"), 
        url(${cerapro_4n_woff}) format("woff"), url(${cerapro_4n_woff2}) format("woff2"), url(${cerapro_4n_ttf}) format("truetype")
    }
    @font-face {
        font-family: "Cera Pro";
        font-weight: 500;
        font-style: normal;
        src: url(${cerapro_5n_eot}); src: url(${cerapro_5n_eot}?#iefix) format("embedded-opentype"), 
        url(${cerapro_5n_woff}) format("woff"), url(${cerapro_5n_woff2}) format("woff2"), url(${cerapro_5n_ttf}) format("truetype")
    }
    @font-face {
        font-family: "Cera Pro";
        font-weight: 700;
        font-style: normal;
        src: url(${cerapro_7n_eot}); src: url(${cerapro_7n_eot}?#iefix) format("embedded-opentype"), 
        url(${cerapro_7n_woff}) format("woff"), url(${cerapro_7n_woff2}) format("woff2"), url(${cerapro_7n_ttf}) format("truetype")
    }
`;
