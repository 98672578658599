import React ,{ useEffect} from 'react';

import gsap from 'gsap';

import HeadMenu from "./HeadMenu";
import Footer from "../components/Footer";
import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url';

import FormatSanityContent from '../scripts/FormatSanityContent.js';
import {useContent} from '../hooks/content-context';
import {useLanguage} from '../hooks/language-context';
import useDynamicRefs from 'use-dynamic-refs';

import Contact from "./Contact";
import { Main } from "../styles/findtree_style";

import { Target,BoxGradient, TextContainer, SectionWrapper, StepBlock, StepText, FeatureIcon, SectionWhite, SectionGray } from "../styles/beratung_style";

 const sanityImageBuilder = imageUrlBuilder(sanityClient);

 function getSanityImageUrl(source) {
    return sanityImageBuilder.image(source)
  }

function Beratung(props) {

    const {state: {language}} = useLanguage();
    const {state: {contentBeratung}} = useContent();
    const {state: {contentTestimonials}} = useContent();
    const [getRef, setRef] =  useDynamicRefs();
    

useEffect(()=>{
    window.scrollTo(0, 0);}
    ,[]);

    useEffect(() => {
        if(contentTestimonials){
            
    const valuesTimeline = gsap.timeline({repeat: -1});
    contentTestimonials.map((testimonial,index)=>{
    valuesTimeline.fromTo(getRef(testimonial._key).current, { scale: 0.9, opacity: 0 },{ scale: 1, opacity: 1, duration: 1 },"-=0.5");
    valuesTimeline.to(null,{},"+=5");
    valuesTimeline.to(getRef(testimonial._key).current,{ scale: 0.9, opacity: 0, duration: 1 });
}
    );

       // const valuesTimeline = gsap.timeline({repeat: -1});
       // valuesTimeline.fromTo(getRef('step1').current, { scale: 0.9, opacity: 0 },{ scale: 1, opacity: 1, duration: 1 });
       // valuesTimeline.to(null,{},"+=5");
       // valuesTimeline.to(getRef('step1').current,{ scale: 0.9, opacity: 0, duration: 1 });
    
  
    
    }
    },[contentTestimonials])

    return (
        <Main>
            <HeadMenu allPosts={props.allPosts} />

            <SectionWhite>
                <TextContainer>
                    <h1>{contentBeratung && FormatSanityContent(contentBeratung[0],language,"headline")}</h1>
                    <p> 
                    {contentBeratung && FormatSanityContent(contentBeratung[0],language,"paragraph")}
                    </p>
                </TextContainer>            
            </SectionWhite>
            
                <BoxGradient>

                <SectionWrapper >
                    {contentTestimonials && contentTestimonials.map((testimonial, index) =>
                        <StepBlock key={testimonial._key} ref={setRef(testimonial._key)}>
                            
                            <FeatureIcon >
                                <div><img src={getSanityImageUrl(testimonial.featureimage.asset).width(300).url()} alt="logo" /></div>
                            </FeatureIcon>

                            <StepText>
                                <p>
                                {FormatSanityContent(testimonial,language,"paragraph")}
                                </p>
                                <h2>{FormatSanityContent(testimonial,language,"headline")}</h2>
                            </StepText>
                        </StepBlock>
                    )
                    }
                        
                  

                </SectionWrapper>

                </BoxGradient>


            <Contact />
            <Footer />
        </Main>
    );
}

export default Beratung;