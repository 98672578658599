import tw, { styled } from 'twin.macro';



export const TextContainer = styled.main`
${tw`w-full translate-y-0 translate-x-0 transform  h-auto`};
& {

    h1{
      ${tw`text-3xl mb-8 font-bold leading-7 text-nothotBlack-500  sm:text-5xl sm:truncate text-center `}
    }
    p{
      ${tw`mb-16 text-base text-justify lg:text-center lg:px-32 xl:px-40 2xl:px-48 text-gray-600`}
    }
    #quote{
      ${tw`italic`}
    }
    #justify{
      ${tw`text-justify`}
    }
  }`



export const ImgGallery = styled.main`
${tw`mx-auto  w-full overflow-x-hidden h-auto`};
& {

  div{
    ${tw` w-full h-auto overflow-x-hidden  flex flex-col lg:flex-row`}
    &{
      div{
      ${tw`text-2xl font-bold leading-7 text-gray-900  sm:text-3xl pb-6 lg:p-3`}
        &{
          img{ 
          ${tw`object-cover	 max-h-96 w-full rounded-lg`}
        }
        }
      }
    }
  }
}`


export const Buttons = styled.div`
${tw`flex flex-row flex-wrap justify-center items-center pt-4 `};
& {
  #gradient{
    ${tw`h-10 px-5 bg-gradient-to-br from-nothotPink-500  via-nothotBlue-500 to-nothotCyan-500  text-white rounded-lg m-1 whitespace-nowrap `}
  }
  #outlined{
    ${tw`h-10 px-5 border border-nothotBlue-500 text-nothotBlue-500 rounded-lg m-1`}
  }
}`

export const MiddlePart = styled.div`
${tw`relative z-40`};
& {}`

export const SectionWhite = styled.div`
${tw`relative  py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex bg-white flex-wrap shadow-inner z-40`};
& {}`

export const SectionGray = styled.div`
${tw`relative  py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex flex-wrap bg-gray-100 shadow-inner z-40`};
& {}`

export const SectionWrapper = styled.div`
${tw`block  lg:flex flex-wrap w-full`};
& {}`



export const StepBlock = styled.div`
${tw`flex lg:block relative pb-12 content-center lg:w-1/3`};
& {}`

export const StepStrokeBegin = styled.div`
${tw`h-full w-20 lg:h-20 lg:w-1/2 absolute flex items-center justify-center lg:right-0`};
& {
  div{
    ${tw`h-full w-px lg:max-h-px lg:w-full bg-gradient-to-b lg:bg-gradient-to-r from-nothotRed-500 to-nothotPink-500 pointer-events-none `}
  }
}`
export const StepStrokeCenter = styled.div`
${tw`h-full w-20 lg:h-20 lg:w-full absolute flex items-center justify-center`};
& {
  div{
    ${tw`h-full w-px lg:max-h-px lg:w-full bg-gradient-to-b from-nothotPink-500 to-nothotBlue-500 lg:to-nothotPink-500 pointer-events-none`}
  }
}`
export const StepStrokeEnd = styled.div`
${tw`h-0 w-0 lg:h-20 lg:w-1/2 absolute flex items-center justify-center lg:left-0`};
& {
  div{
    ${tw`h-full w-px lg:max-h-px lg:w-full lg: bg-gradient-to-r  from-nothotPink-500 to-nothotBlue-500 pointer-events-none`}
  }
}`


export const StepIcon = styled.div`
${tw`flex justify-center`};
& {
  div{
    ${tw`flex-shrink-0 w-20 h-20 rounded-full inline-flex items-center align-middle justify-center text-white relative z-10 text-4xl font-bold`}
    &{
        svg{
          ${tw`w-10 h-10`}
        }
      }
    }
}`

export const StepText = styled.main`
${tw`flex-grow pl-4 lg:pl-10 lg:pr-10 pt-0 lg:pt-4 min-w-0`};
& {
  h2{
    ${tw`font-medium text-lg text-nothotBlack-500 mb-1 tracking-wider text-left lg:text-center uppercase`}
  }
  p{
    ${tw`leading-relaxed text-left lg:text-center text-gray-600 min-w-0 break-words`}
  }

}`

//p-4 md:w-1/3 flex flex-col text-center items-center
export const FeatureBlock = styled.div`
${tw`flex lg:block relative pb-12 content-center lg:w-1/3`};
& {}`

export const FeatureIcon = styled.div`
${tw`flex justify-center`};
& {
  div{
    ${tw`flex-shrink-0 w-20 h-20 rounded-full inline-flex items-center align-middle justify-center relative z-10 border border-nothotBlue-500`}
    &{
      img{
        ${tw`w-10 h-10`}
      }
      }
    }
}`

export const FeatureText = styled.main`
${tw`flex-grow pl-4 lg:pl-10 lg:pr-10 pt-0 lg:pt-4 min-w-0`};
& {
  h1{
    ${tw`font-medium text-lg text-nothotBlack-500 mb-1 tracking-wider text-left lg:text-center uppercase`}
  }
  p{
    ${tw`leading-relaxed text-left lg:text-center text-red-500 break-words` }
  }
}`

export const Color1 = styled.div`
${tw` bg-nothotRed-500 `}`
export const Color2 = styled.div`
${tw` bg-nothotPink-500 `}`
export const Color3 = styled.div`
${tw` bg-nothotBlue-500 `}`
