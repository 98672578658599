import tw, { styled } from 'twin.macro';

export const SectionWhite = styled.div`
${tw`py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex flex-wrap shadow-inner`};
& {}`

export const SectionGray = styled.div`
${tw`py-28 px-6 lg:px-32 md:px-20 xl:px-56 2xl:px-80 flex flex-wrap bg-gray-100 shadow-inner`};
& {}`

export const TextContainer = styled.div`
${tw`w-full translate-y-0 translate-x-0 transform overflow-x-hidden h-auto`};
& {

    h1,h2{
      ${tw` mb-8 font-bold leading-7 text-nothotBlack-500 text-left ml-48  sm:text-5xl sm:truncate md:leading-7 md:text-3xl  `}
    }
    h3,h4{
      ${tw` mb-2 font-bold leading-3 text-nothotBlack-500 text-left ml-48  sm:text-xl sm:truncate md:leading-3 md:text-xl  `}
    }
    p{
      ${tw`mb-8 max-w-7xl text-base text-justify lg:text-justify lg:px-32 xl:px-40 2xl:px-48 text-gray-500`}
    }
    ul{
      ${tw` mt-8 mb-8 max-w-7xl text-base text-justify lg:text-justify lg:px-32 xl:px-40 2xl:px-48 text-gray-500`}
    }
    ol{
      ${tw` mt-8 mb-8 max-w-7xl text-base text-justify lg:text-justify lg:px-32 xl:px-40 2xl:px-48 text-gray-500`}
    }
    li{
      ${tw`list-disc mt-4 mb-4  text-base text-justify text-gray-500`}
    }
    #quote{
      ${tw`italic`}
    }
  }`

